export default {
  BUY_TICKET : 'BUY_TICKET',
  BUY_TICKET_SUCCESS : 'BUY_TICKET_SUCCESS',
  BUY_TICKET_ERROR : 'BUY_TICKET_ERROR',
  DONATE: 'DONATE',
  DONATE_SUCCESS: 'DONATE_SUCCESS',
  DONATE_ERROR: 'DONATE_ERROR',
  SUBSCRIBE: 'SUBSCRIBE',
  SUBSCRIBE_SUCCESS: 'SUBSCRIBE_SUCCESS',
  SUBSCRIBE_ERROR: 'SUBSCRIBE_ERROR',
  TOGGLE_DONATE_MODAL: 'TOGGLE_DONATE_MODAL',
  TOGGLE_IS_PROCCESSING : 'TOGGLE_IS_PROCCESSING',
  GET_TRANSACTIONS: 'GET_TRANSACTIONS',
  GET_TRANSACTIONS_SUCCESS: 'GET_TRANSACTIONS_SUCCESS',
  GET_TRANSACTIONS_ERROR: 'GET_TRANSACTIONS_ERROR',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_ERROR: 'CANCEL_SUBSCRIPTION_ERROR',
  UPDATE_CARD: 'UPDATE_CARD',
  UPDATE_CARD_SUCCESS: 'UPDATE_CARD_SUCCESS',
  UPDATE_CARD_ERROR: 'UPDATE_CARD_ERROR'
}