import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { webinarsActions } from '@actions'
import { config } from '@config'

const Websites = () => {
	const { clinic_websites, patients_websites } = useSelector(state => state.webinarsReducer)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(webinarsActions.getLinks())
	}, [])

  return (
    <main class="content">
				<section class="websites websites--page">
					<div class="websites__content">
						<div class="page__subtitle">For Clinicians</div>
						<div class="websites__list">

							{ clinic_websites?.map(site => 
								<a class="list__item" href={site?.url}  key={site?.id}>
									<div class="card">
										{/* <figure class="website__logo">
											<img class="image" src={config.REACT_APP_IMAGE_URL + site?.image?.url}  alt="WEBSITENAME" />
										</figure> */}
										<h2 class="website__title">{site?.title}</h2>
										<div class="website__description">{site?.sub_title}</div>
									</div>
								</a>
							)}
						</div>
						<div class="page__subtitle">For Patients</div>
						<div class="websites__list">
							{ patients_websites?.map(site => 
									<a class="list__item" href={site?.url}  key={site?.id}>
										<div class="card">
											{/* <figure class="website__logo">
												<img class="image" src={config.REACT_APP_IMAGE_URL + site?.image?.url}  alt="WEBSITENAME" />
											</figure> */}
											<h2 class="website__title">{site?.title}</h2>
											<div class="website__description">{site?.sub_title}</div>
										</div>
									</a>
								)}
						</div>
					</div>
				</section>
			</main>
  )
}

export default Websites
