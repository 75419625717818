import React from 'react'
import ContentWrapper from '@components/Markup/ContentWrapper'
import Presentations from '@components/Presentations'

const PresentationsPage = () => {
  return (
    <ContentWrapper>
      <Presentations />
    </ContentWrapper>
  )
}

export default PresentationsPage
