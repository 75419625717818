import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Popup = ({ open = false, handleClose, placeholder }) => {
  const { user }  = useSelector(state => state.authReducer)

  return (
    <section id="popups" className={`popup ${open ? 'presentations show' : ''}`}>
		<div className="popup__overlay">
			<div className="popup__block popup--presentations">
				<div className="block__top">
					<button className="btns btns-close" onClick={handleClose}></button>
				</div>
				<div className="block__title">{ placeholder || `To access this webinar, you must be a member of the PCDSI` }</div>
				<div className="block__content">
					<p>
          To join the PCDSI, please create an account and go to the "My Subscriptions" page. If you are already a member, please log in.
						{/* To join PCDSI please create an account or sign in. <br />
						Go to the "My Subscriptions" page and follow the conditions. */}
					</p>	
				</div>
				{ user ?  
         <div className="block__btns">
          <Link to="/subscriptions" onClick={handleClose} className="btns btns-opacity">My Subscriptions</Link>
        </div>
        :
        <>
          <div className="block__btns">
            <Link to="/login" onClick={handleClose} className="btns btns-opacity">Log in</Link>
          </div>
          <div className="block__bottom">
            <Link to="/register" onClick={handleClose} className="link" >Don’t have an account?</Link>
          </div>
        </>
        }
			</div>
		</div>
	</section>
  )
}

export default Popup
