import React from 'react'
import { useSelector } from 'react-redux'

import RegisterStepOne from './RegisterStepOne'
import RegisterStepTwo from './RegisterStepTwo'

const Register = () => {
  const { step } = useSelector(state => state.authReducer)

  const steps = [
    <RegisterStepOne />,
		<RegisterStepTwo />
	]

  return steps[step]
}

export default Register
