import {takeEvery, put, call, select} from 'redux-saga/effects';
import {PROFILE_ACTIONS, LOCAL_STORAGE} from '@constants';
import {push} from 'connected-react-router'
import {profileActions, globalActions} from '../actions';
import {authActions} from '../actions';
import {filterBlackList} from '@helpers';
// import {getUserSelector} from '../selectors/auth';
import {processRequest} from '../../services/Api';
import {getUserSelector, getStepSelector} from '../selectors/auth'
import {ROUTES} from '@router/routes';
import {removeToken} from '@services/LocalStorage'

function* handleFollow(action) {
  try {
    const {payload} = action;
    const {id} = payload;
    const {data} = yield call(
      processRequest,
      `/followings/follow?product_id=${id}`,
      'POST',
      {},
    );
    if (data.message === 'Success') {
      yield put(profileActions.followSuccess(id));
    }
  } catch (e) {
    console.log(e);
    yield put(profileActions.followError(e));
  }
}

function* handleUnfollow(action) {
  try {
    const {payload} = action;
    const {id} = payload;
    const requestPayload = {user_id: id};
    const {data} = yield call(
      processRequest,
      `/followings/unfollow?product_id=${id}`,
      'POST',
      {},
    );
    if (data.message === 'Success') {
      yield put(profileActions.unfollowSuccess(id));
    }
  } catch (e) {
    console.log(e);
    yield put(profileActions.unfollowError(e));
  }
}

function* handleGetUserDetails(action) {
  try {
    const {payload: {user_id}} = action || {};
    const {data} = yield call(processRequest,`/users/${user_id}`);
    if (data && data.user) {
      const {user, user_position} = data;
      user.user_position = user_position;
      yield put(profileActions.getUserDetailsSuccess(user));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];
        // TODO errorMessage.match('email') instead of keys[0] === 'email'
        if (keys[0] === 'password') {
          message = "The password field can't be blank";
        } else {
          message =
            error_messages && keys[0] === 'email'
              ? `${errorMessage}`
              : `${keys[0]} ${errorMessage}`;
        }
      }

      yield put(profileActions.getUserDetailsError(message));
    } else if (status === 401) {
      yield put(authActions.loginError(error));
    } else if (status === 500) {
      yield put(profileActions.getUserDetailsError(statusText));
    } else if (e.message) {
      yield put(profileActions.getUserDetailsError(e.message));
    } else {
      yield put(profileActions.getUserDetailsError('Internal server error.'));
    }
  }
}

function* handlegetUserSelector() {
  try {
    const {data} = yield call(processRequest,`/users/profile`);
    if ((data && data.user)) {
      const {user} = data;
      yield put(authActions.loginSuccess(user?.data?.attributes));
      localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(user?.data?.attributes));
    }
  } catch (e) {
    console.log({e})
    const {data, status, statusText} = e || {};
    const {error} = data || {};
    if (status === 401) {
      yield put(profileActions.getCurrentUserError(error));
      console.log('error blyat')
      removeToken()
    } else if (status === 500) {
      yield put(profileActions.getCurrentUserError(statusText));
    } else if (e.message) {
      yield put(profileActions.getCurrentUserError(e.message));
    } else {
      yield put(profileActions.getCurrentUserError('Internal server error.'));
    }
  }
}

function* handleBlock(action) {
  try {
    const {payload} = action;
    const {id} = payload;
    const {data} = yield call(
      processRequest,
      `/users/block/${id}`,
      'PUT',
    );
    if (data.message === 'Success') {
      const currentUser = yield select(getUserSelector);
      const {i_block_user_ids} = currentUser;
      yield put(profileActions.blockSuccess(id));
      const newBlackList = Array.isArray(i_block_user_ids)?i_block_user_ids:[];
      newBlackList.push(id);
      currentUser.i_block_user_ids = newBlackList;
      localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(currentUser));
    }
  } catch (e) {
    console.log(e);
    yield put(profileActions.blockUnblockError(e));
  }
}

function* handleUnblock(action) {
  try {
    const {payload} = action;
    const {id} = payload;
    const {data} = yield call(
      processRequest,
      `/users/unblock/${id}`,
      'PUT',
    );
    if (data.message === 'Success') {
      yield put(profileActions.unblockSuccess(id));
      const currentUser = yield select(getUserSelector);
      const {i_block_user_ids} = currentUser;
      const newBlackList = i_block_user_ids.filter(el => el !== id)
      currentUser.i_block_user_ids = newBlackList;
      localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(currentUser));
    }
  } catch (e) {
    console.log(e);
    yield put(profileActions.blockUnblockError(e));
  }
}

function* handleGetFollowers(action) {
  const {
    payload: {meta},
  } = action || {};
  const {page, perPage} = meta || {};
  const getFollowersSuccess =
    page === 1
      ? profileActions.getFollowersSuccess
      : profileActions.getFollowersNextPageSuccess;

  try {
    const {data} = yield call(
      processRequest,
      `/followers?page=${page}&per_page=${perPage}`,
      'GET',
    );

    if (data.users) {
      const user = yield select(getUserSelector);
      const users = filterBlackList({
        users: data.users,
        blackList: user.i_block_user_ids || [],
        blockedMe: user.me_block_user_ids || [],
      });
      yield put(getFollowersSuccess(users, data.total_pages));
    } else {
      yield put(profileActions.getFollowersError(data));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${errorMessage}`;
      }

      yield put(profileActions.getFollowersError(message));
    } else if (status === 401) {
      yield put(authActions.logout());
    } else if (status === 500) {
      yield put(profileActions.getFollowersError(statusText));
    } else if (e.toJSON?.().message === "Network Error") {
      yield put(profileActions.getFollowersError(e.message));
    } else {
      yield put(profileActions.getFollowersError('Internal server error.'));
    }
  }
}

function* handleGetFollowing(action) {
  const {
    payload: {meta},
  } = action || {};
  const {page, search, perPage} = meta || {};
  const getFollowingSuccess =
    page === 1
      ? profileActions.getFollowingSuccess
      : profileActions.getFollowingNextPageSuccess;

  try {
    const {data} = yield call(
      processRequest,
      `/followers/users?page=${page}&per_page=${perPage}&query=${
        search || ''
      }`,
      'GET',
    );

    if (data.users) {
      const user = yield select(getUserSelector);
      const users = filterBlackList({
        users: data.users,
        blackList: user.i_block_user_ids || [],
        blockedMe: user.me_block_user_ids || [],
      });
      yield put(getFollowingSuccess(users, data.total_pages));
    } else {
      yield put(profileActions.getFollowingError(data));
    }
  } catch (e) {

    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${errorMessage}`;
      }

      yield put(profileActions.getFollowingError(message));
    } else if (status === 401) {
      yield put(authActions.logout());
    } else if (status === 500) {
      yield put(profileActions.getFollowingError(statusText));
    } else if (e.toJSON?.().message === "Network Error") {
      yield put(profileActions.getFollowingError(e.message));
    } else {
      yield put(profileActions.getFollowingError('Internal server error.'));
    }
  }
}

function* handleGetPetsRatings(action) {
  console.log(1211)
  const {
    payload: {petsRatingUsersMeta},
  } = action || {};
  const {page, perPage} = petsRatingUsersMeta || {};
  const getRatingsSuccess =
    page === 1
      ? profileActions.getPetsRatingsSuccess
      : profileActions.getPetsRatingsNextPageSuccess;

  try {
    const {data} = yield call(
      processRequest,
      `/rating?page=${page}&per_page=${perPage}&tab=pet`,
      'GET',
    );

    if (data.users) {
      const currentUser = yield select(getUserSelector);
      const user = {
        ...currentUser,
        current_user_postition: data.current_user_postition,
      };
      if(currentUser.user_type==='pet'){
        const user = {
        ...currentUser,
        user_position: data.current_user_postition,
      };
      const jsonUser = JSON.stringify({user});
      // yield call(AsyncStorage.setItem, LOCAL_STORAGE.USER, jsonUser);
      yield put(profileActions.updateProfileSuccess(user));
      // yield put(authActions.signUpSuccess(user));
    }
      const users = filterBlackList({
        users: data.users,
        blackList: user.i_block_user_ids || [],
        blockedMe: user.me_block_user_ids || [],
      });
      yield put(getRatingsSuccess(users, data.total_pages));
    } else {
      yield put(profileActions.getRatingsError(data));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${errorMessage}`;
      }

      yield put(profileActions.getRatingsError(message));
    } else if (status === 401) {
      yield put(authActions.logout());
    } else if (status === 500) {
      yield put(profileActions.getRatingsError(statusText));
    } else if (e.toJSON?.().message === "Network Error") {
      yield put(profileActions.getRatingsError(e.message));
    } else {
      yield put(profileActions.getRatingsError('Internal server error.'));
      console.log(e)
    }
  }
}

function* handleGetServicesRatings(action) {
  const {
    payload: {servicesRatingUsersMeta},
  } = action || {};
  const {page, perPage} = servicesRatingUsersMeta || {};
  const getRatingsSuccess =
    page === 1
      ? profileActions.getServicesRatingsSuccess
      : profileActions.getServicesRatingsNextPageSuccess;

  try {
    const {data} = yield call(
      processRequest,
      `/rating?page=${page}&per_page=${perPage}&tab=service`,
      'GET',
    );

    if (data.users) {
      const currentUser = yield select(getUserSelector);
      if(currentUser.user_type==='service'){
        const user = {
          ...currentUser,
          user_position: data.current_user_postition,
        };
        const jsonUser = JSON.stringify({user});
        // yield call(AsyncStorage.setItem, LOCAL_STORAGE.USER, jsonUser);
        yield put(profileActions.updateProfileSuccess(user));
        // yield put(authActions.signInSuccess(user));
    }
      const users = filterBlackList({
        users: data.users,
        blackList: currentUser.i_block_user_ids || [],
        blockedMe: currentUser.me_block_user_ids || [],
      });
      yield put(getRatingsSuccess(users, data.total_pages));
    } else {
      yield put(profileActions.getRatingsError(data));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    console.log(e)

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${errorMessage}`;
      }

      yield put(profileActions.getRatingsError(message));
    } else if (status === 401) {
      yield put(authActions.logout());
    } else if (status === 500) {
      yield put(profileActions.getRatingsError(statusText));
    } else if (e.toJSON?.().message === "Network Error") {
      yield put(profileActions.getRatingsError(e.message));
    } else {
      yield put(profileActions.getRatingsError('Internal server error.'));
    }
  }
}

function* handleUpdatePassword(action) {
  try {
    const {
      payload: {passwords},
    } = action || {};
    const pToken = localStorage.getItem('pToken');
    const formData = new FormData();
    formData.append('[password]', passwords.password);
    formData.append('[password_confirmation]', passwords.password_confirmation);

    const {data} = yield call(
      processRequest,
      `/passwords/${pToken}`,
      'PUT',
      formData,
      false
    );
    localStorage.removeItem('pToken');

    if (data.message === 'Password updated.') {
      yield put(push(ROUTES.LOGIN));
      yield put(profileActions.updatePasswordSuccess());
      yield put(globalActions.toggleSuccessModal({ show: true, message: 'Password was successfuly changed' }))
    } else {
      yield put(profileActions.updatePasswordError(data));
      yield put(globalActions.toggleSuccessModal({ show: true, message: 'Something went wrong, try again' }))
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};
    console.log(e);

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(profileActions.updatePasswordError(message));
    } else if (status === 500) {
      yield put(profileActions.updatePasswordError(statusText));
    } else if (e.message) {
      yield put(profileActions.updatePasswordError(e.message));
    } else {
      yield put(profileActions.updatePasswordError('Internal server error.'));
    }
  }
}

function* handleUpdateProfile(action) {
  try {
    const {
      payload: {user, register, redirect},
    } = action || {};
    // const requestPayload = {user};
    const formData = new FormData();
    const keys = Object.keys(user);
    keys.forEach((key) => {
      if (
        key !== 'id' &&
        key !== 'avatar' &&
        key !== 'password' &&
        key !== 'avatar_url' &&
        key !== 'points' &&
        key !== 'service_type_ids' &&
        user[key] !== null
      ) {
        formData.append(`user[${key}]`, user[key]);
      }
    });
    const {data} = yield call(
      processRequest,
      `/users/${user.id}`,
      'PUT',
      formData,
      false,
    );

    if (data.user) {
      console.log({data})
      const jsonUser = JSON.stringify(data.user);
      localStorage.setItem(LOCAL_STORAGE.USER, jsonUser);
      yield put(profileActions.updateProfileSuccess(data.user.data.attributes));
      yield put(authActions.loginSuccess(data.user.data.attributes));
      if(register) {
        const step = yield select(getStepSelector)
        if(step < 1) {
          yield put(authActions.stepAdd())
        } else {
          yield put(push(ROUTES.WEBINARS))
        }
      }
      if(redirect) {
        yield put(push(ROUTES.WEBINARS))
      }
    } else {
      yield put(profileActions.updateProfileError(data));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(profileActions.updateProfileError(message));
    } else if (status === 401) {
      yield put(authActions.logout());
    } else if (status === 500) {
      yield put(profileActions.updateProfileError(statusText));
    } else if (e.message) {
      yield put(profileActions.updateProfileError(e.message));
    } else {
      yield put(profileActions.updateProfileError('Internal server error.'));
    }
  }
}

function* handleUploadAvatar(action) {
  try {
    const {
      payload: {avatar, register, user},
    } = action || {};
    const formData = new FormData();
    formData.append('user[avatar]', avatar);
        // } else {
        //   formData.append(`user[${key}]`, user[key]);

    console.log(formData)
    const {data} = yield call(
      processRequest,
      `/users/${user.id}`,
      'PUT',
      formData,
      false,
    );

    if (data.user) {
      // const jsonUser = JSON.stringify({user: data.user});
      // yield call(AsyncStorage.setItem, LOCAL_STORAGE.USER, jsonUser);
      // yield put(profileActions.uploadAvatarSuccess(data.user));
      yield put(authActions.loginSuccess(data?.user?.data?.attributes));      
    } else {
      yield put(profileActions.uploadAvatarError(data));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};
    console.log(e)

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];
        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(profileActions.uploadAvatarError(message));
    } else if (status === 401) {
      yield put(authActions.logout());
    } else if (status === 500) {
      yield put(profileActions.uploadAvatarError(statusText));
    } else if (e.message) {
      yield put(profileActions.uploadAvatarError(e.message));
    } else {
      yield put(profileActions.uploadAvatarError('Internal server error.'));
    }
  }
}

function* handleGetUsers(action) {
  const {
    payload: {usersMeta},
  } = action || {};
  const {page, perPage, search} = usersMeta || {};
  const getUserSuccess =
    page === 1
      ? profileActions.getUserSuccess
      : profileActions.getUsersNextPageSuccess;

  try {
    const {data} = yield call(
      processRequest,
      `/users?page=${page}&per_page=${perPage}&query=${search}`,
      'GET',
    );

    if (data.users) {
      const user = yield select(getUserSelector);
      const users = filterBlackList({
        users: data.users,
        blackList: user.i_block_user_ids || [],
        blockedMe: user.me_block_user_ids || [],
      });
      yield put(getUserSuccess(users, data.total_pages));
    } else {
      yield put(profileActions.getUsersError(data));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${errorMessage}`;
      }

      yield put(profileActions.getUsersError(message));
    } else if (status === 401) {
      yield put(authActions.logout());
    } else if (status === 500) {
      yield put(profileActions.getUsersError(statusText));
    } else if (e.message) {
      yield put(profileActions.getUsersError(e.message));
    } else {
      yield put(profileActions.getUsersError('Internal server error.'));
    }
  }
}


function* handleReportUser(action) {
  try {
    const {
      payload: {reportable},
    } = action || {};
    const {id, body} = reportable || {};
    const requestPayload = {report: {body}};
    const {data} = yield call(
      processRequest,
      `/reports/report_for_user?id=${id}`,
      'POST',
      requestPayload,
    );

    if (data.message) {
      yield put(profileActions.reportUserSuccess(id));
      yield put(profileActions.block(id));
      yield put(globalActions.toggleReportModal(null, false))
      yield put(globalActions.toggleSuccessModal({ show: true, message: 'User was reported, and immediately added to black list'}))
      // add alert('we received your report, user is automatically added to your black list, you will not see any content from this user and will not be able to interact with each other untill you unblock them')
    } else {
      yield put(profileActions.reportUserError(data));
    }
  } catch (e) {
      yield put(profileActions.reportUserError(e));
  }
}

function* handleGetBlackList() {
  try {

    const {data} = yield call(processRequest, '/blocked_users', 'GET');

    if (data.users) {
      yield put(profileActions.getBlackListSuccess(data.users));
    } else {
      yield put(profileActions.getBlackListError(data));
    }
  } catch (e) {

    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${errorMessage}`;
      }

      yield put(profileActions.getBlackListError(message));
    } else if (status === 500) {
      yield put(profileActions.getBlackListError(statusText));
    } else if (e.message) {
      yield put(profileActions.getBlackListError(e.message));
    } else {
      yield put(profileActions.getBlackListError('Internal server error.'));
    }
  }
}

function* handleSaveBuyer(action) {
  try {
    const {
      payload: {buyer},
    } = action || {};
    // const requestPayload = {user};
    const formData = new FormData();
    const keys = Object.keys(buyer);
    keys.forEach((key) => {
      if (
        key !== 'avatar' &&
        key !== 'avatar_url' &&
        key !== 'points' &&
        key !== 'service_type_ids' &&
        buyer[key] !== null
      ) {
        formData.append(`user[${key}]`, buyer[key]);
      }
    });
    const {data} = yield call(
      processRequest,
      `/users/${buyer.id}`,
      'PUT',
      formData,
      false,
    );

    if (data.user) {
      const jsonUser = JSON.stringify(data.user);
      localStorage.setItem(LOCAL_STORAGE.USER, jsonUser);
      yield put(profileActions.saveBuyerSuccess(data.user.data.attributes));
      yield put(authActions.loginSuccess(data.user.data.attributes));
      yield put(push(ROUTES.HOME));
    } else {
      yield put(profileActions.saveBuyerError(data));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(profileActions.saveBuyerError(message));
    } else if (status === 401) {
      yield put(authActions.logout());
    } else if (status === 500) {
      yield put(profileActions.saveBuyerError(statusText));
    } else if (e.message) {
      yield put(profileActions.saveBuyerError(e.message));
    } else {
      yield put(profileActions.saveBuyerError('Internal server error.'));
    }
  }
}

function* handleChangePassword(action) {
  try {
    const {
      payload: {passwords},
    } = action || {};
    // const requestPayload = {user};
    const requestPayload = {
      old_password: passwords.old_password,
      password: passwords.password,
      password_confirmation: passwords.password_confirmation,
    };
    const {data} = yield call(
      processRequest,
      `/passwords/change`,
      'PUT',
      requestPayload,
    );

    if (data.user) {
      yield put(profileActions.changePasswordSuccess());
      yield put(push(ROUTES.HOME));
    } else {
      yield put(profileActions.changePasswordError(data));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(profileActions.changePasswordError(message));
    } else if (status === 401) {
      yield put(authActions.logout());
    } else if (status === 500) {
      yield put(profileActions.changePasswordError(statusText));
    } else if (e.message) {
      yield put(profileActions.changePasswordError(e.message));
    } else {
      yield put(profileActions.changePasswordError('Internal server error.'));
    }
  }
}

function* handleChangeEmail(action) {
  try {
    const {
      payload: {emails},
    } = action || {};
    // const requestPayload = {user};
    const requestPayload = {
      old_email: emails.old_email,
      email: emails.email,
      email_confirmation: emails.email_confirmation,
    };
    const {data} = yield call(
      processRequest,
      `/emails/change`,
      'PUT',
      requestPayload,
    );

    if (data.message === 'Email updated') {
      const oldUserJSON = localStorage.getItem(LOCAL_STORAGE.USER);
      const oldUser = JSON.parse(oldUserJSON);
      const newUser = {
        ...oldUser,
        email: emails.email,
      };
      const jsonUser = JSON.stringify(newUser);
      localStorage.setItem(LOCAL_STORAGE.USER, jsonUser);
      yield put(authActions.loginSuccess(newUser));
      yield put(profileActions.changeEmailSuccess());
      yield put(push(ROUTES.HOME));
    } else {
      yield put(profileActions.changeEmailError(data));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(profileActions.changeEmailError(message));
    } else if (status === 401) {
      yield put(authActions.logout());
    } else if (status === 500) {
      yield put(profileActions.changeEmailError(statusText));
    } else if (e.message) {
      yield put(profileActions.changeEmailError(e.message));
    } else {
      yield put(profileActions.changeEmailError('Internal server error.'));
    }
  }
}

export function* watchProfileSagas() {
  yield takeEvery(PROFILE_ACTIONS.GET_USER_DETAILS, handleGetUserDetails)
  yield takeEvery(PROFILE_ACTIONS.FOLLOW, handleFollow);
  yield takeEvery(PROFILE_ACTIONS.UNFOLLOW, handleUnfollow);
  yield takeEvery(PROFILE_ACTIONS.GET_FOLLOWERS, handleGetFollowers);
  yield takeEvery(PROFILE_ACTIONS.GET_FOLLOWING, handleGetFollowing);
  yield takeEvery(PROFILE_ACTIONS.UPDATE_PROFILE, handleUpdateProfile);
  yield takeEvery(PROFILE_ACTIONS.GET_USERS, handleGetUsers);
  yield takeEvery(PROFILE_ACTIONS.UPLOAD_AVATAR, handleUploadAvatar);
  yield takeEvery(PROFILE_ACTIONS.BLOCK, handleBlock);
  yield takeEvery(PROFILE_ACTIONS.UNBLOCK, handleUnblock);
  yield takeEvery(PROFILE_ACTIONS.REPORT_USER, handleReportUser);
  yield takeEvery(PROFILE_ACTIONS.GET_PETS_RATINGS, handleGetPetsRatings);
  yield takeEvery(PROFILE_ACTIONS.GET_SERVICES_RATINGS, handleGetServicesRatings);
  yield takeEvery(PROFILE_ACTIONS.UPDATE_PASSWORD, handleUpdatePassword);
  yield takeEvery(PROFILE_ACTIONS.GET_CURRENT_USER, handlegetUserSelector);
  yield takeEvery(PROFILE_ACTIONS.GET_BLACK_LIST, handleGetBlackList);
  yield takeEvery(PROFILE_ACTIONS.SAVE_BUYER_REQUEST, handleSaveBuyer);
  yield takeEvery(PROFILE_ACTIONS.CHANGE_PASSWORD, handleChangePassword);
  yield takeEvery(PROFILE_ACTIONS.CHANGE_EMAIL, handleChangeEmail);
}
