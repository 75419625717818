import {GLOBAL_ACTIONS, INITIAL_STATE} from '@constants';

const globalReducer = (state = INITIAL_STATE.GlobalInitialState, action) => {
  const {type, payload} = action || {};
  const {loading, showLoginModal, showBuyTicketButton, showRegisterModal, modal, successMessage, error, reportUserId,  toggle} =
    payload || {};

  switch (type) {
    case GLOBAL_ACTIONS.SET_LOADING:
      if(typeof loading === "boolean") {
        return {
          ...state,
          loading: loading
        };
      } else {
        return {
          ...state
        }
      }
    case GLOBAL_ACTIONS.TOGGLE_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: showLoginModal || false,
        showBuyTicketButton: showBuyTicketButton || false,
      };
    case GLOBAL_ACTIONS.TOGGLE_REGISTER_MODAL:
      return {
        ...state,
        showRegisterModal: showRegisterModal || false,
        showBuyTicketButton: showBuyTicketButton || false
      };
    case GLOBAL_ACTIONS.TOGGLE_SUCCESS_POPUP:
      const { show, message } = modal || {};

      return {
        ...state,
        successPopup: {
          show,
          message
        }
      }
    case GLOBAL_ACTIONS.TOGGLE_REPORT_MODAL: {
      return {
        ...state,
        reportModal: {
          show: toggle,
          reportUserId: reportUserId
        }
      }
      
    }
    case GLOBAL_ACTIONS.SET_ERROR:
    case GLOBAL_ACTIONS.SEND_CONTACT_US_ERROR:
      return {
        ...state,
        error: error || '',
      };
    case GLOBAL_ACTIONS.CLEAR_ERROR:
      return {
        ...state,
        error: '',
      };
    case GLOBAL_ACTIONS.SET_SUCCESS:
    case GLOBAL_ACTIONS.SEND_CONTACT_US_SUCCESS:
      return {
        ...state,
        successMessage: successMessage || '',
      };
    case GLOBAL_ACTIONS.CLEAR_SUCCESS:
      return {
        ...state,
        successMessage: '',
      };
      case GLOBAL_ACTIONS.LOGOUT_SUCCESS:
        return INITIAL_STATE.GlobalInitialState
    default:
      return state;
  }
};

export {globalReducer};