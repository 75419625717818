import React from 'react'
import ContentWrapper from '@components/Markup/ContentWrapper'
import WebinarDetail from '@components/WebinarDetail'

const WebinarDetailPage = () => {
  return (
    <ContentWrapper>
      <WebinarDetail />
    </ContentWrapper>
  )
}

export default WebinarDetailPage
