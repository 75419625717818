import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { paymentsActions, profileActions } from "@actions";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import moment from "moment";
import CancelPopup from "./CancelPopup";
import SuccessPopup from "@components/SuccessPopup";

const Subscriptions = () => {
  const stripe = useStripe();
  const elements = useElements(CardElement);
  const { user } = useSelector((state) => state.authReducer);
  const { isProcessing, transactions } = useSelector(
    (state) => state.paymentsReducer
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(paymentsActions.getTransactions());
  }, []);

  const style = {
    base: {
      fontSize: "18px",
      color: "#424770",
      lineHeight: "40px",
      borderRadius: "10px",
      "::placeholder": {
        color: "#aab7c4",
      },
      backgroundColor: "#f1f3f6",
      padding: "10% 10%",
    },
    invalid: {
      color: "#9e2146",
    },
  };

  const handleSubscribe = () => {
    const cardElement = elements.getElement(CardElement);
    dispatch(paymentsActions.subscribe({ cardElement, stripe }));
  };

  const handleCancelSubscription = () => {
    dispatch(paymentsActions.cancelSubscription());
  };

  const handleUpdateCard = () => {
    const cardElement = elements.getElement(CardElement);
    dispatch(paymentsActions.updateCard({ cardElement, stripe }));
  };

  const subtractDate = (date1, date2) => {
    let difference = date1.getTime() - date2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };

  return (
    <main class='content'>
      <section class='subscription subscription--page'>
        <div class='subscription__content'>
          { user?.subscription_status == 'active' ? (
            <>
              <h1 class='page__title'>My Subscription</h1>
              <div class='subscription__block'>
                <div class='block__title'>Current Plan</div>
                <div class='block__text'>
                  <p>
                    Your payment will be automatically renewed each year. You
                    can cancel your subscription at any time by clicking on
                    “Cancel Subscription”
                  </p>
                </div>
              </div>
              <div class='subscription__block'>
                <div class='block__title'>Payment Method</div>
                <div class='subscription__form'>
                  <div class='form form--payment'>
                    <div class='form__row form__row--columns sub--mobile'>
                      <div class='form__row' style={{ width: "80%" }}>
                        <div className='card-wrapper'>
                          <CardElement
                            options={{ style, hidePostalCode: true }}
                          />
                        </div>
                      </div>
                      <div class='form__row'>
                        <button
                          disabled={isProcessing}
                          onClick={handleUpdateCard}
                          class={`btns btns-blue btns-update ${
                            isProcessing ? "disabled" : ""
                          }`}
                          style={{
                            marginRight: "15px",
                            backgroundColor: "rgba(179, 217, 245, 0.6)",
                            color: "#259fff",
                          }}
                        >
                          {" "}
                          {isProcessing
                            ? "Is processing..."
                            : "Update Card"}{" "}
                        </button>
                        <button
                          disabled={isProcessing}
                          onClick={() => setOpen(true)}
                          class={`btns btns-blue btns-update ${
                            isProcessing ? "disabled" : ""
                          }`}
                        >
                          {" "}
                          {isProcessing
                            ? "Is processing..."
                            : "Cancel Subscription"}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <form class='form form--update'>
                    <div class='form__row form__row--columns'>
                      <div class='form__row  form__row--card'>
                        <input
                          class='form__input form__input--card'
                          type='text'
                          name='cNumber'
                          size='17'
                          placeholder='Credit card'
                          value=''
                        />
                        <i class='input__info icons i24x24 bg i-info'>i</i>
                        <div class='form__hint'>
                          Your card details are protected. <br />
                          You are the only one who can access them.
                        </div>
                      </div>
                      <div class='form__row form__row--subcolumns'>
                        <div class='form__row'>
                          <input
                            class='form__input form__input--exp'
                            type='text'
                            name='cExp'
                            placeholder='MM/YY'
                            size='6'
                            maxlength='5'
                            value=''
                          />
                        </div>
                        <div class='form__row'>
                          <input
                            class='form__input form__input--cvc'
                            type='text'
                            name='cCVC'
                            placeholder='CVC'
                            size='3'
                            maxlength='3'
                            value=''
                          />
                        </div>
                      </div>
                      <div class='form__row form__row--btns'>
                        <input
                          class='btns btns-blue btns-save'
                          type='submit'
                          value='Save'
                        />
                        <input
                          class='btns btns-opacity btns-cancel'
                          type='reset'
                          value='Cancel'
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class='subscription__block'>
                <div class='block__title'>Payment History</div>
                <div class='block__text'>
                  {transactions?.map((el, i) => (
                    <div className='latest-payments'>
                      <p>
                        Payment Date{" "}
                        {moment(el?.created_at).format("DD/MM/YYYY")}
                      </p>
                      <p style={{ fontSize: "12px" }}></p>
                      {subtractDate(new Date(), new Date(el?.created_at)) <
                      30 ? (
                        <p>
                          <a
                            class='link link--invoces'
                            href={el.invoice_pdf_url}
                            download
                          >
                            Download payment receipt
                          </a>
                        </p>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <h1 class='page__title'>My Subscription</h1>
              <div class='subscription__block'>
                <div class='block__text'>
                  <p>
                    An annual subscription costs € 40. Please enter your credit
                    or debit card details below.
                  </p>
                </div>
              </div>
              <div class='subscription__block'>
                <div class='block__title'>Payment Method</div>
                <div class='subscription__form'>
                  <div class='form form--payment'>
                    <div class='form__row form__row--columns'>
                      <div class='form__row'>
                        <div className='card-wrapper'>
                          <CardElement
                            options={{ style, hidePostalCode: true }}
                          />
                        </div>
                      </div>
                      <div class='form__row'>
                        <button
                          disabled={isProcessing}
                          onClick={handleSubscribe}
                          class={`btns btns-blue btns-update ${
                            isProcessing ? "disabled" : ""
                          }`}
                        >
                          {" "}
                          {isProcessing ? "Is processing..." : "Subscribe"}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <form class='form form--update'>
                    <div class='form__row form__row--columns'>
                      <div class='form__row  form__row--card'>
                        <input
                          class='form__input form__input--card'
                          type='text'
                          name='cNumber'
                          size='17'
                          placeholder='Credit card'
                          value=''
                        />
                        <i class='input__info icons i24x24 bg i-info'>i</i>
                        <div class='form__hint'>
                          Your card details are protected. <br />
                          You are the only one who can access them.
                        </div>
                      </div>
                      <div class='form__row form__row--subcolumns'>
                        <div class='form__row'>
                          <input
                            class='form__input form__input--exp'
                            type='text'
                            name='cExp'
                            placeholder='MM/YY'
                            size='6'
                            maxlength='5'
                            value=''
                          />
                        </div>
                        <div class='form__row'>
                          <input
                            class='form__input form__input--cvc'
                            type='text'
                            name='cCVC'
                            placeholder='CVC'
                            size='3'
                            maxlength='3'
                            value=''
                          />
                        </div>
                      </div>
                      <div class='form__row form__row--btns'>
                        <input
                          class='btns btns-blue btns-save'
                          type='submit'
                          value='Save'
                        />
                        <input
                          class='btns btns-opacity btns-cancel'
                          type='reset'
                          value='Cancel'
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <CancelPopup open={open} handleClose={() => setOpen(false)} />
      <SuccessPopup />
    </main>
  );
};

export default Subscriptions;
