export const ROUTES = {
  LANDING: '/',
  PROFILE: '/profile',
  SUBSCRIPTIONS: '/subscriptions',
  MEMBERSHIP: '/membership',
  PRESENTATIONS: '/presentations',
  WEBINARS: '/webinars',
  WEBINAR: '/webinar/:id',
  WEBSITES: '/websites',
  LOGIN: '/login',
  REGISTER: '/register',
  LOGIN: '/LOGIN',
  FORGOT: '/FORGOT',
  FORGOT_PASSWORD: '/FORGOT/:password_token',
  PRIVACY_POLICY: '/privacy-policy',
  UPCOMING_MEETINGS: '/upcoming'
}
