import axios from 'axios';
import {LOCAL_STORAGE} from '../redux/constants';
import {config} from '../config';
import { store } from '@redux/store'
import { authActions } from '@actions'
import { getToken, getRefreshToken, setToken, removeToken, removeRefreshToken } from './LocalStorage'

export function processRequest(
  url = '',
  method = 'GET',
  data = {},
  json = true,
  responseType
) {

  const token = getToken()
  console.log(token)

  const headers = {
    'Content-Type': json ? 'application/json' : 'multipart/form-data',
    Authorization: token || '',
  };
  
  const body = json ? JSON.stringify(data) : data;
  const configs = {
    method,
    baseURL: config.REACT_APP_API_URL + url,
    data: body,
    headers: headers,
    responseType: responseType ? responseType : 'json',
  };

  const instance =  axios.create()
  
  return instance(configs).then((res) => res);
}
