import React from 'react'
import { useHistory } from 'react-router'
import moment from 'moment'

const WebinarCard = ({ webinar }) => {
  const { id, title, description, date_started, time_started, webinar_link, speaker } = webinar
  const history = useHistory()

  const handleRedirect = () => history.push(`/webinar/${id}`)

  return (
    <div className="list__item" style={{ cursor: 'pointer' }} >
      <div className="card card--webinar" onClick={handleRedirect}>
        <div className="card__top">
          <div className="webinar__date">
            <svg className="icons svg i16x16">
              <use xlinkHref="#i-calendar"></use>
            </svg>
            { moment(webinar?.date_started).format('DD MMMM, YYYY') }
          </div>
          <div className="webinar__time">
            <svg className="icons svg i16x16">
              <use xlinkHref="#i-watches"></use>
            </svg>
            { time_started }
          </div>
        </div>
        <div className="webinar__title">
          { title }
        </div>
        <div className="webinar__description">
          <p>
            { description }
          </p>
        </div>
        <div className="webinar__link">
          <a className="btns btns-more">more details</a>
        </div>
      </div>
    </div>
  )
}

export default WebinarCard
