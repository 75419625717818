import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '@actions'

import logoImg from '@img/logo_b.png'
import logoImg2x from '@img/logo_b@2x.png'
import logoImg3x from '@img/logo_b@3x.png'

import { useForm } from "react-hook-form";

const RegisterStepOne = () => {
	const { error } = useSelector(state => state.authReducer)
	const { register, handleSubmit, watch, formState: { errors } } = useForm();
	const dispatch = useDispatch()

	const pass = watch('password')

	const onSubmit = data => {
		dispatch(authActions.signUp({ email: data.email, password: data.password }))
	}

  return (
    <main className="content">
				<section className="signinup signinup--signup">
					<div className="signinup__bg">
						<figure className="signinup__logo">
            <img className="logo" src={logoImg} srcSet={`${logoImg2x} 2x, ${logoImg3x} 3x`}
              alt="PCDSI" />
						</figure>
					</div>
					<div className="signinup__content">
						<div className="page__title">
							Create your account
						</div>
						<div className="signinup__form">
							<form className="form" onSubmit={handleSubmit(onSubmit)}>
								<div className="form__row">
									<label className="form__label" for="email">Email address</label>
									<input { ...register('email', { required: true }) } className="form__input" id="uEmail"  type="email" placeholder="" /> 
									 { errors.email && <p className="form-c">Email is required</p> }
								</div>
								<div className="form__row">
									<label className="form__label" for="uPassword">Password</label>
									<input { ...register('password', { required: true, pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,})/ }) } className="form__input" id="uPassword" type="password" placeholder="" /> 
									{ errors.password && <p className="form-error">Password is too weak. Must be minimum of 6 characters long, with at least one capital letter and one number </p> }
								</div>
								<div className="form__row">
									<label className="form__label" for="password_confirm">Confirm Password</label>
									<input { ...register('password_confirm', { required: true, validate: value =>
            value === pass || "Passwords do not match" }) } className="form__input" id="uRePassword"  type="password" placeholder="" /> 
						{ errors.password_confirm && <p className="form-error">Passwords are required, and should match</p> }
								</div>
								<div className="form__row form__row--tac form__row--submit">
									<input className="form__submit btns-opacity" type="submit" value="Next" />
								</div>
								{ error && <p style={{ color: '#ff0000', textAlign: 'center', marginTop: '10px' }} className="form-error">{error}</p> }
								<div className="form__row form__row-steps form__row--tac">
									<ul className="steps__list">
										<li className="list__item active"><span></span></li>
										<li className="list__item"><span></span></li>
									</ul>
								</div>
							</form>
						</div>
					</div>
				</section>
			</main>
  )
}

export default RegisterStepOne
