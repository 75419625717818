import { PAYMENTS_ACTIONS } from '@constants';

export const buyTicket = (payment) => ({
  type: PAYMENTS_ACTIONS.BUY_TICKET,
  payload: {payment},
});

export const buyTicketSuccess = () => ({
  type: PAYMENTS_ACTIONS.BUY_TICKET_SUCCESS,
  payload: {payment: null},
});

export const buyTicketError = (error) => ({
  type: PAYMENTS_ACTIONS.BUY_TICKET_ERROR,
  payload: {error},
});

export const donate = (payment) => ({
  type: PAYMENTS_ACTIONS.DONATE,
  payload: {payment}
})

export const donateSuccess = () => ({
  type: PAYMENTS_ACTIONS.DONATE_SUCCESS,
  payload: {}
})

export const donateError = (error) => ({
  type: PAYMENTS_ACTIONS.DONATE_ERROR,
  payload: {error}
})

export const subscribe = (payment) => ({
  type: PAYMENTS_ACTIONS.SUBSCRIBE,
  payload: { payment }
})

export const subscribeSuccess = () => ({
  type: PAYMENTS_ACTIONS.SUBSCRIBE_SUCCESS,
  payload: {}
})

export const subscribeError = (error) => ({
  type: PAYMENTS_ACTIONS.SUBSCRIBE_ERROR,
  payload: { error }
})

export const getTransactions = () => ({
  type: PAYMENTS_ACTIONS.GET_TRANSACTIONS,
  payload: {}
})

export const getTransactionsSuccess = (transactions) => ({
  type: PAYMENTS_ACTIONS.GET_TRANSACTIONS_SUCCESS,
  payload: { transactions }
})

export const getTransactionsError = (error) => ({
  type: PAYMENTS_ACTIONS.GET_TRANSACTIONS_ERROR,
  payload: { error }
})

export const toggleDonateModal = (user) => ({
  type: PAYMENTS_ACTIONS.TOGGLE_DONATE_MODAL,
  payload: {user}
})

export const toggleIsProccessing = (proccessing) => ({
  type: PAYMENTS_ACTIONS.TOGGLE_IS_PROCCESSING,
  payload: {proccessing}
})

export const cancelSubscription = () => ({
  type: PAYMENTS_ACTIONS.CANCEL_SUBSCRIPTION,
  payload: {}
})

export const cancelSubscriptionSuccess = () => ({
  type: PAYMENTS_ACTIONS.CANCEL_SUBSCRIPTION_SUCCESS,
  payload: {}
})

export const cancelSubscriptionError = (error) => ({
  type: PAYMENTS_ACTIONS.CANCEL_SUBSCRIPTION_ERROR,
  payload: { error }
})

export const updateCard = (payment) => ({
  type: PAYMENTS_ACTIONS.UPDATE_CARD,
  payload: { payment }
})

export const updateCardSuccess = () => ({
  type: PAYMENTS_ACTIONS.UPDATE_CARD_SUCCESS,
  payload: {  }
})

export const updateCardError = (error) => ({
  type: PAYMENTS_ACTIONS.UPDATE_CARD_ERROR,
  payload: { error }
})

