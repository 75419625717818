import React from 'react'
import { Switch, Route} from "react-router-dom";
import { ROUTES } from './routes';

import PageWrapper from '@components/Markup/PageWrapper'
import LandingPage from '@pages/LandingPage';
import MembershipPage from '@pages/MembershipPage';
import WebinarsPage from '@pages/WebinarsPage';
import WebinarDetailPage from '@pages/WebinarDetailPage';
import WebsitesPage from '@pages/WebsitesPage';
import PresentationsPage from '@pages/PresentationsPage';
import LoginPage from '@pages/LoginPage';
import RegisterPage from '@pages/RegisterPage';
import ProfilePage from '@pages/ProfilePage';
import SubscriptionPage from '@pages/SubscriptionPage';
import ForgotPasswordPage from '@pages/ForgotPasswordPage';
import ForgotPage from '@pages/ForgotPage';
import PrivacyPolicyPage from '@pages/PrivacyPolicy';
import UpcomingMeetingsPage from '@pages/UpcomingMeetingsPage';

const Routes = () => {
  return (
    <>
       <PageWrapper>
          <Switch>
            <Route exact path={ROUTES.LANDING} component={LandingPage} />
            <Route exact path={ROUTES.MEMBERSHIP} component={MembershipPage} />
            <Route exact path={ROUTES.WEBINARS} component={WebinarsPage} />
            <Route exact path={ROUTES.WEBINAR} component={WebinarDetailPage} />
            <Route exact path={ROUTES.WEBSITES} component={WebsitesPage} />
            <Route exact path={ROUTES.PROFILE} component={ProfilePage} />
            <Route exact path={ROUTES.PRESENTATIONS} component={PresentationsPage} />
            <Route exact path={ROUTES.SUBSCRIPTIONS} component={SubscriptionPage} />
            <Route exact path={ROUTES.LOGIN} component={LoginPage} />
            <Route exact path={ROUTES.REGISTER} component={RegisterPage} />
            <Route exact path={ROUTES.FORGOT} component={ForgotPage} />
            <Route exact path={ROUTES.UPCOMING_MEETINGS} component={UpcomingMeetingsPage} />
            <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordPage} />
            <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicyPage} />
          </Switch>
        </PageWrapper>
    </>
  )
}

export default Routes
