import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { webinarsActions } from "@actions";

import Pagination from "@components/Pagination";
import Search from "@components/Search";
import WebinarCard from "./WebinarCard";

const Webinars = () => {
  const { webinars, meta } = useSelector((state) => state.webinarsReducer);
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(webinarsActions.getWebinars(meta, true));
  }, []);

  const handlePageChange = (page) =>
    dispatch(webinarsActions.getWebinars({ ...meta, page: page }, true));

  const handleSearch = (value) =>
    dispatch(webinarsActions.getWebinars({ ...meta, query: value }, true));
  const handleClearSearch = () =>
    dispatch(webinarsActions.getWebinars({ ...meta, query: "" }, true));

  const handleChangeTab = (tab) => {
    if (meta.scope !== tab) {
      dispatch(webinarsActions.getWebinars({ ...meta, scope: tab }, true));
    }
  };

  return (
    <>
      <main className='content'>
        <section className='webinars webinars--page'>
          {user?.id ? (
            <div class='disclaimer'>
              <p style={{ marginBottom: 0 }}>
                Information or recommendations in the slides are that of the
                individual speaker, rather than the PCDSI
              </p>
            </div>
          ) : null}
          <div className='webinars__content'>
            <h1 className='page__title'>Webinars</h1>

            <Search
              defaultValue={meta?.query}
              onSearch={handleSearch}
              onSearchClear={handleClearSearch}
            />

            <div className='tabs'>
              <div
                onClick={() => handleChangeTab("recorded")}
                className={`tab tab-recorded ${
                  meta.scope == "recorded" ? "active" : null
                }`}
              >
                recorded
              </div>
              <div
                onClick={() => handleChangeTab("upcoming")}
                className={`tab tab-upcoming ${
                  meta.scope == "upcoming" ? "active" : null
                }`}
              >
                upcoming
              </div>
            </div>

            {webinars?.length ? (
              <div className='webinars__list'>
                {webinars.map((webinar) => (
                  <WebinarCard webinar={webinar} key={webinar?.id} />
                ))}
              </div>
            ) : (
              // no webinars
              <div className='webinars__empty'>
                <div className='empty__text'>
                  <p>
                    Details of upcoming webinars will be published here soon
                  </p>
                </div>
                <div className='empty__icons'>
                  <i className='icon'></i>
                </div>
              </div>
            )}

            {meta?.total_pages && (
              <Pagination
                totalPages={meta?.total_pages}
                onPageChange={handlePageChange}
                currentPage={meta?.page}
              />
            )}
          </div>
        </section>
      </main>
    </>
  );
};

export default Webinars;
