import React from 'react'
import ContentWrapper from '@components/Markup/ContentWrapper'
import Subscriptions from '@components/Subscriptions'

const SubsciptionsPage = () => {
  return (
    <ContentWrapper>
      <Subscriptions />
    </ContentWrapper>
  )
}

export default SubsciptionsPage
