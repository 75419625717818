import React from 'react'
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
  return (
    <div className="terms" >
      <p>Primary Care Dermatology Society of Ireland (registered charity) t/a PCDSI care of Abbey Medical Centre, Ennis, Co. Clare is a registered charity in Ireland. PCDSI provides postgraduate medical education in dermatology to general practitioners and physicians for the benefit of the wider population.
      </p>
      <p>PCDSI respects your privacy and is committed to protecting your personal data. If you have questions about this privacy statement, please contact pcdsimembers@gmail.com.
      </p>
      <ol>
        <li>
          General
            <p>1.1 This privacy statement or policy (“the Statement”) explains how PCDSI collects and treats information about its users.
            </p>
            <p>
              1.2 Reference to Personal Data has the meaning assigned to it in the General Data Protection Regulation and includes any information relating to an identified or identifiable natural person.
            </p>
            <p>1.3 Anyone who downloads, saves, installs, uses, accesses, attempts to use or access the PCDSI    application or services, or connects/integrates another service with PCDSI shall by so doing be deemed to have agreed to the terms of this privacy Statement and to the collection, use and other processing of data in accordance with the terms of this Statement.
            </p>
            <p>1.4 Nothing in this Statement is intended to limit any user’s statutory data processing rights.
            </p>
            <p>1.5 Users may contact PCDSI at any time regarding its processing of personal data at pcdsimembers@gmail.com.</p>
        </li>
        <li>
        What data do we collect?
          <p>2.1 Profile information is the identifying information users provide to PCDSI on registration and for the purposes of providing our services. Profile information includes the following: Name, IMC number, work address, email address and telephone.</p>
          <p>2.2 Technical data includes IP address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.</p>
          <p>2.3 Cookies which are small files that a site transfers to your computer’s hard drive through your web browser (if you allow) that enables it to recognise your browser and capture and remember certain information to allow you to navigate faster through the site, and generally improve the user experience. These may include remembering User preferences and passwords. At present PCDSI does not collect cookies.</p>
        </li>
        <li>How does PCDSI collect your data
          <ul>3.1 You directly provide PCDSI with most of the data we collect. We collect and 	process data when you:
            <li>Sign up to our website;</li>
            <li>Log in to the website with your credentials;</li>
            <li>Create or modify your user profile</li>
            <li>Communicate with us and knowingly provide other information to PCDSI</li>
          </ul>
        </li>
        <li>How does PCDSI use or process personal data?
          <ul>4.1 PCDSI collects personal data so that it can:
            <li>manage your account;</li>
            <li>customise the content of the website for you;</li>
            <li>improve our services and notify users of modifications;</li>
            <li>respond to users' communications and provide effective customer care and support;</li>
            <li>keep our services safe and secure and prevent misuse of the services;</li>
            <li>seek professional legal advice if necessary and;</li>
            <li>comply with its legal obligations, including Court orders, audit or regulatory requirements and in order to maintain proper books and records.</li>
          </ul>
          <p>4.2 PCDSI may maintain audit logs detailing when you log into our software and the actions that you perform to safeguard against unauthorised access.
          </p>
          <p>4.3 PCDSI does not intend to process special categories of personal data, such as data on racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person's sex life or sexual orientation.</p>
        </li>
        <li>Legal Basis for Processing of Personal Data
          <ul>
          5.1 PCDSI processes personal data based on one or more of the following lawful grounds:
            <li>Express user consent;</li>
            <li>Processing necessary for the performance of PCDSI’s contractual obligations;</li>
            <li>Compliance with PCDSI’s legal obligations;</li>
            <li>Furtherance of PCDSI’s legitimate interests.</li>
          </ul>
          <p>
          5.2 PCDSI processes personal data necessary to provide our services to the user or to respond to user service support requests. PCDSI may anonymise and aggregate data for the purpose of machine learning and statistics in order to improve the Services.
        </p>
        </li>
        <li>
          Personal Data and Third-Party Integrations
          <p>6.1 All payments for the services are processed through the Stripe payment platform.</p>
          <p>6.2 PCDSI does not collect any information on your method of payment (e.g. the credit card you use) and any personal data provided to Stripe for this purpose is the subject of the Stripe privacy Statement.
          </p>
          <p>6.3 In using the Services, you acknowledge and agree that your payment is subject to the Stripe Services Agreement - Ireland (the “Stripe Services Agreement”) and the Stripe Privacy Policy.
          </p>
        </li>
        <li>
          How do we store your data?
          <p>7.1 The data controller is PCDSI, a charity based in Clare, Ireland.</p>
          <p>7.2 PCDSI data is stored on servers situated within the European Union and we do not transfer Personal Data outside of the European Economic Area.</p>
          <p>7.3 PCDSI may use specified sub-processors to assist in providing the services for example, for storage or IT support purposes. When using sub-processors, PCDSI implements appropriate measures to ensure no personal data is disclosed to such sub-processors save as is necessary to provide the relevant assistance in delivering the Services. Where any sub-processors are based in countries outside the EU which are not considered to provide adequate protection for personal data, PCDSI will only allow access to personal data subject to appropriate safeguards provided by sub-processors, such as a services agreement subject to standard data protection clauses adopted by the EU Commission. </p>
          <p>7.4 PCDSI will keep personal data for as long as necessary to provide our services to the user.</p>
          <p>7.5 Personal data will be deleted or anonymised as soon as possible after the termination of a user’s account, unless it is required to be stored in order for PCDSI to fulfil statutory obligations or compliance with Court orders.</p>
        </li>
        <li>
          Transfer of personal data to others
          <p>
            8.1 PCDSI may from time to time disclose personal data to its officers or persons 	directly employed by it but only where necessary for the purpose of ensuring services, 	support and security of that data.
          </p>
        </li>
        <li>
          Marketing
          <p>9.1 PCDSI would like to send information about our services that we think you 	might be interested in. We always ask for your express consent as to whether you 	want to receive this information and by what medium. If you agree to receive 	marketing information, you may always opt out at a later date. You have the 	right at any time to request PCDSI to stop contact you for marketing purposes. If you 	no longer wish to be contacted for marketing purposes, please email 	pcdsimembers@gmail.com.</p>
        </li>
        <li>
          What are your data protection rights?
          <p>10.1 PCDSI would like to make sure you are fully aware of all your data protection rights. Every user is entitled to:
            <ul>
              <li>Right to Information. 
                You are entitled to be informed as to what personal data PCDSI processes, why PCDSI needs it and the legal basis for that processing. This Statement is designed to provide this essential information and further information can be sought by contacting us at pcdsimembers@gmail.com.
                </li>
                <li>
                Right to Rectification. 
                If any Personal Data is incorrect or misleading, you have the right to request that PCDSI correct any information which you believe is inaccurate and to complete information which you believe is incomplete.
                </li>
                <li>
                Right to Erasure. 
                You have the right to request PCDSI to erase your data subject to the conditions set out in data protection legislation, including where its retention is no longer necessary is relation to the purpose for which it was processed or where it has been unlawfully processed.
                </li>
                <li>
                Right to Restriction of Processing. 
                You have the right to request PCDSI to erase your data subject to the conditions set out in data protection legislation, such as where you have objected to the data processing or you contest the accuracy of the data and that objection is being investigated, or where there has been unlawful process or the data is required for the purpose of a legal claim.
                </li>
                <li>
                Right to Data Portability. 
                You have the right to request PCDSI to transfer your data to another organisation in a structured, commonly used and machine-readable format subject to the conditions set out in data protection legislation, including where the processing is based on consent or contract and is done by automated means.
                </li>
                <li>
                  Right to Object to Processing. 
                  You have the right to object to PCDSI’s processing of personal data:
                  <ul>
                    <li>at any stage where it is use to send you marketing information</li>
                    <li>where it is based on our legitimate interests until we can establish that PCDSI’s compelling legitimate interest override rights or interests, or that we require the data for the establishment, exercise or defence of legal claims.</li>
                  </ul>
                </li>
                <p>If you make a request, we have one month to respond to you, unless we 	request an extension of two further months, within that first month.</p>
                <p>If you would like to exercise any of these rights, please contact us at our email: pcdsimembers@gmail.com. For ease of processing, please include “data protection” 	in the subject of any communication.</p>
            </ul>
            <p>If you have raised a data protection concern with us and you are not satisfied 	with our response, you have the right to submit a complaint with the Data 	Protection Commissioner or other supervisory authority.
            </p>
          </p>
        </li>
        <li>
          Cookies
          <p>Subject to your consent, PCDSI may permit specified third parties such as Stripe and Google Analytics to place cookies on your device to facilitate online payments, and to report usage statistics of the Website.</p>
          <p>
          Cookies are small text files that are used to store or retrieve information so that certain functions can perform. Cookies are used by many websites to do a number of things e.g. remember your visits to a website; your preferences on that website; and counting the number of people visiting a website. A further explanation about cookies is available on: http://www.allaboutcookies.org/ and http://www.youronlinechoices.eu/.
          </p>
          <p>
          Deletion of cookies and regulating cookies permissions can be done through your web browser and guidance on this can be found in the help pages of your web browser.
          </p>
          <p>Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.</p>
        </li>
        <li>
          Change Of Policy
          <p>PCDSI may amend or repeal this privacy statement at any time by posting a revised privacy policy or a new policy document in its place. If such revised or new policy includes a significant change to the way that Personal Data may be treated, PCDSI will notify registered users of the fact that its privacy policy has changed by sending users an email to the address associated with their user account.</p>
        </li>
      </ol> 
    </div>
  )
}

export default PrivacyPolicy
