import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { profileActions } from '@actions'

import logoImg from '@img/logo_b.png'
import logoImg2x from '@img/logo_b@2x.png'
import logoImg3x from '@img/logo_b@3x.png'

// import Select from 'react-select'
import CustomSelect from '@components/CustomSelect'
import { useForm } from "react-hook-form";

const Profile = () => {
	const { user } = useSelector(state => state.authReducer)
	const temp = user?.is_have_other_qualifications ? 'Yes' : 'No'
	const [checked, setChecked] = useState(temp)
	const { register, handleSubmit, formState: { errors }, control } = useForm();
	const dispatch = useDispatch()

	const userTypes = [ 
		{ value: 'gp', label: 'GP' }, 
		{ value: 'gp_trainee', label: 'GP Trainee' },
		{ value: 'dermatology_trainee', label: 'Dermatology Trainee' },
		{ value: 'dermatology_registrar', label: 'Dermatology Registrar' },
		{ value: 'nchd', label: 'NCHD' },
		{ value: 'nurse', label: 'Nurse' },
		{ value: 'medical_student', label: 'Medical Student' },
		{ value: 'other', label: 'Other' },
	];

	const otherQualifications = [
		{ value: 'certificate', label: 'Certificate' },
		{ value: 'diploma', label: 'Diploma' },
		{ value: 'masters', label: 'Masters' },
	]

	const countyTypes = [ 'Antrim', 'Armagh', 'Carlow', 'Cavan','Clare', 'Cork', 'Derry', 'Donegal', 'Down', 'Dublin North','Dublin South', 'Fermanagh', 'Galway', 'Kerry', 'Kildare', 'Kilkenny', 'Laois', 'Leitrim', 'Limerick','Longford', 'Louth', 'Mayo', 'Meath', 'Monaghan', 'Offaly', 'Roscommon', 'Sligo', 'Tipperary', 'Tyrone','Waterford', 'Westmeath', 'Wexford', 'Wicklow' ].map(el => ({ value: el, label: el  }));

	useEffect(() => {
		setChecked(user?.is_have_other_qualifications ? 'Yes' : 'No')
	}, [user?.is_have_other_qualifications])

	const onSubmit = data => {
		if(checked == 'Yes') {
			dispatch(profileActions.updateProfile({
				...data,
				user_type: data.user_type?.value ? data.user_type?.value : data.user_type,
				county: data.county?.value ? data.county?.value : data.county,
				is_have_other_qualifications: true,
				other_qualification:  data.other_qualification?.value ? data.other_qualification?.value : data.other_qualification,
				id: user?.id
			 }, false, true))
		} else {
			dispatch(profileActions.updateProfile({
				...data,
				user_type: data.user_type?.value ? data.user_type?.value : data.user_type,
				county: data.county?.value ? data.county?.value : data.county,
				is_have_other_qualifications: false,
				other_qualification: null,
				id: user?.id
			 }, false, true))
		}
	}

	// todo

  return user ? (
    <main class="content">
				<section class="profile profile--page">
					<div class="profile__content">
						<h1 class="page__title">My profile</h1>
						<div class="profile__form">
							<form class="form" onSubmit={handleSubmit(onSubmit)}>
								<div class="form__row form__row--columns">
									<div class="form__row column--2">
										<label className="form__label" htmlFor="first_name">Your Firstname</label>
										<input defaultValue={user.first_name} { ...register('first_name') } className="form__input" id="uFName"  type="text" placeholder=""  /> 
										{ errors.first_name && <p className="form-error">This field is required</p> }
									</div>
									<div class="form__row column--2">
										<label  className="form__label" htmlFor="last_name">Your Surname</label>
										<input { ...register('last_name') } className="form__input" id="uSName" type="text" placeholder="" defaultValue={user.last_name} /> 
										{ errors.last_name && <p className="form-error">This field is required</p> }
									</div>
								</div>
								<div class="form__row form__row--columns">
									<div class="form__row column--2">
									<label className="form__label" htmlFor="imc_number">IMC number</label>
									<input   { ...register('imc_number') } defaultValue={user?.imc_number} className="form__input" id="uIMC" type="text" placeholder="" /> 
									{ errors.imc_number && <p className="form-error">This field is required</p> }
									</div>
									<div class="form__row custom-select column--2">
										<label className="form__label" htmlFor="user_type">I am a: </label>
										<CustomSelect required defaultValue={user.user_type} name="user_type" control={control} options={userTypes} />
										{ errors.user_type && <p className="form-error">This field is required</p> }
									</div>
								</div>
								<div class="form__row form__row--columns">
									<div class="form__row column--2 custom-select">
										<label className="form__label" htmlFor="county">County</label>
										<CustomSelect required defaultValue={user.county} name="county" control={control} options={countyTypes} />
										{ errors.county && <p className="form-error">This field is required</p> }
									</div>
									<div class="form__row column--2">
										<label className="form__label" htmlFor="phone">Telephone</label>
										<input { ...register('phone') } defaultValue={user.phone} className="form__input" id="uPhone" type="phone" placeholder="" /> 
										{ errors.phone && <p className="form-error">This field is required</p> }
									</div>
								</div>
								<div class="form__row column">
									<label className="form__label" htmlFor="work_address">Work address</label>
									<input  { ...register('work_address') } defaultValue={user.work_address} className="form__input" id="uWAdress" type="text" placeholder="" /> 
								</div>
								<div class="form__row form__row--radio">
									<label className="form__label">Do you hold other post graduate qualifications in the area o Dermatology ? </label>
									<input onChange={() => setChecked('Yes')} checked={checked == 'Yes'} className="form__radio" id="uPostYes" name="uPost" type="radio" placeholder="" value="yes" /> 
									<label className="form__label form__label--radio"  htmlFor="uPostYes">Yes</label>
									<input onChange={() => setChecked('No')} checked={checked == 'No'} className="form__radio" id="uPostNo" name="uPost" type="radio" placeholder="" value="no" /> 
									<label className="form__label form__label--radio"  htmlFor="uPostNo">No</label>
								</div>
								<div className={`form__row custom-select ${ checked == 'Yes' ? '' : 'disabled' } `}>
									{ checked == 'Yes' ?
										<>
										<label className="form__label" htmlFor="other_qualification">Other qualifications</label>
											<CustomSelect required defaultValue={user.other_qualification} name="other_qualification" control={control} options={otherQualifications} />
											{ errors.other_qualification && <p className="form-c">This field is required</p> }
										</>
										: <label className="form__label">Choose from dropdown </label>
									}
								</div>
								<div class="form__row form__row--tac form__row--submit">
									<input class="form__submit btns-blue" type="submit" value="Save changes" />
								</div>
							</form>
						</div>
					</div>
				</section>
			</main>
  ) : <p>Loading</p>
}

export default Profile
