import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '@actions'
import { useParams } from 'react-router';
import { useForm } from "react-hook-form";

const ForgotPassword = () => {
  const { password_token } = useParams()
  const { error }  = useSelector(state => state.authReducer)
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const pass = watch('password')
  const dispatch = useDispatch()
  
  const onSubmit = data => dispatch(authActions.restorePassword(data, password_token));

  return (
    <main class="content">
				<section class="profile profile--page">
					<div class="profile__content">
						<h1 class="page__title">Forgot Password</h1>
							<form class="form" onSubmit={handleSubmit(onSubmit)}>

              <div className="form__row">
									<label className="form__label" for="uPassword">Password</label>
									<input { ...register('password', { required: true, pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,})/ }) } className="form__input" id="uPassword" type="password" placeholder="" /> 
                  { errors.password && <p className="form-error">Password is too weak. Must be minimum of 6 characters long, with at least one capital letter and one number </p> }
								</div>
								

								<div className="form__row">
									<label className="form__label" for="password_confirm">Confirm Password</label>
									<input { ...register('password_confirmation', { required: true, validate: value =>
                    value === pass || "Passwords do not match" }) } className="form__input" id="uRePassword"  type="password" placeholder="" /> 
                    { errors.password_confirmation && <p className="form-error">Passwords are required, and should match</p> }
								</div>
                <div className="form__row form__row--tac form__row--submit">
									<input className="form__submit btns-opacity" type="submit" value="Update Password" />
                  { error && <p className="form-error">{error}</p> }
								</div>
              </form>
					</div>
				</section>
			</main>
  )
}

export default ForgotPassword
