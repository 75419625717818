import React from 'react'
import ContentWrapper from '@components/Markup/ContentWrapper'
import UpcomingMeetings from '../components/UpcomingMeetings'

const UpcomingMeetingsPage = () => {
  return (
    <ContentWrapper>
      <UpcomingMeetings />
    </ContentWrapper>
  )
}

export default UpcomingMeetingsPage
