import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '@actions'
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';

import logoImg from '@img/logo_b.png'
import logoImg2x from '@img/logo_b@2x.png'
import logoImg3x from '@img/logo_b@3x.png'

const Login = () => {
  const { error }  = useSelector(state => state.authReducer)
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch()
  
  const onSubmit = data => dispatch(authActions.login(data.email, data.password));

  return (
    <main className="content">
				<section className="signinup signinup--signin">
					<div className="signinup__bg">
						<figure className="signinup__logo">
							<img className="logo" src={logoImg} srcSet={`${logoImg2x} 2x, ${logoImg3x} 3x`}
              alt="PCDSI" />
						</figure>
					</div>
					<div className="signinup__content">
						<div className="page__title">
							Log in to your account
						</div>
						<div className="signinup__form">
							<form className="form" onSubmit={handleSubmit(onSubmit)}>
								<div className="form__row">
									<label className="form__label" for="uEmail">Email address</label>
									<input 
                    className="form__input" 
                    id="uEmiail" 
                    type="email" 
                    { ...register('email', { required: true }) } 
                  /> 
                  { errors?.email && <p className="form-error">This field is required</p> }
								</div>
								<div className="form__row">
									<label className="form__label" for="uPassword">Password</label>
									<input 
                    className="form__input" 
                    id="uPassword" 
                    type="password" 
                    { ...register('password', { required: true }) }
                  /> 
                  { errors?.password && <p className="form-error">This field is required</p> }
								</div>
								<div className="form__row form__row--tac form__row--submit">
									<input className="form__submit btns-opacity" type="submit" value="Log in" />
                  { error && <p className="form-error">{error}</p> }
								</div>
								<div className="form__row form__row--tac">
									<Link className="link" to='/forgot'>Forgot password?</Link>
								</div>
							</form>
						</div>
					</div>
				</section>
			</main>
  )
}

export default Login
