import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { profileActions } from '@actions'

import logoImg from '@img/logo_b.png'
import logoImg2x from '@img/logo_b@2x.png'
import logoImg3x from '@img/logo_b@3x.png'

// import Select from 'react-select'
import CustomSelect from '@components/CustomSelect'
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom'

const RegisterStepTwo = () => {
	const [checked, setChecked] = useState('')
	const { user } = useSelector(state => state.authReducer)
	const { register, handleSubmit, formState: { errors }, control } = useForm();
	const dispatch = useDispatch()

	const userTypes = [ 
		{ value: 'gp', label: 'GP' }, 
		{ value: 'gp_trainee', label: 'GP Trainee' },
		{ value: 'dermatology_trainee', label: 'Dermatology Trainee' },
		{ value: 'dermatology_registrar', label: 'Dermatology Registrar' },
		{ value: 'nchd', label: 'NCHD' },
		{ value: 'nurse', label: 'Nurse' },
		{ value: 'medical_student', label: 'Medical Student' },
		{ value: 'other', label: 'Other' },
	];

	const otherQualifications = [
		{ value: 'certificate', label: 'Certificate' },
		{ value: 'diploma', label: 'Diploma' },
		{ value: 'masters', label: 'Masters' },
	]

	const countyTypes = [ 'Antrim', 'Armagh', 'Carlow', 'Cavan','Clare', 'Cork', 'Derry', 'Donegal', 'Down', 'Dublin North','Dublin South', 'Fermanagh', 'Galway', 'Kerry', 'Kildare', 'Kilkenny', 'Laois', 'Leitrim', 'Limerick','Longford', 'Louth', 'Mayo', 'Meath', 'Monaghan', 'Offaly', 'Roscommon', 'Sligo', 'Tipperary', 'Tyrone','Waterford', 'Westmeath', 'Wexford', 'Wicklow' ].map(el => ({ value: el, label: el  }));

	const onSubmit = data => {
		dispatch(profileActions.updateProfile({
			...data,
			is_have_other_qualifications: checked == 'Yes' ? true : false,
			id: user?.id
		 }, true))
	}

  return (
		<main className="content">
		<section className="signinup signinup--signup">
			<div className="signinup__bg">
				<figure className="signinup__logo">
					<img className="logo" src={logoImg} srcSet={`${logoImg2x} 2x, ${logoImg3x} 3x`}
              alt="PCDSI" />
				</figure>
			</div>
			<div className="signinup__content">
				<div className="page__title">
					Create your account
				</div>
				<div className="signinup__form">
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						<div className="form__row form__row--columns">
							<div className="form__row column--2">
								<label className="form__label" htmlFor="first_name">Your Firstname</label>
								<input { ...register('first_name', { required: true }) } className="form__input" id="uFName"  type="text" placeholder="" /> 
								{ errors.first_name && <p className="form-error">This field is required</p> }
							</div>
							<div className="form__row column--2">
								<label  className="form__label" htmlFor="last_name">Your Surname</label>
								<input { ...register('last_name', { required: true }) } className="form__input" id="uSName" type="text" placeholder=""/> 
								{ errors.last_name && <p className="form-error">This field is required</p> }
							</div>
						</div>
						<div className="form__row form__row--columns">
							<div className="form__row column--2">
								<label className="form__label" htmlFor="imc_number">IMC number</label>
								<input  { ...register('imc_number', { required: true }) } className="form__input" id="uIMC" type="text" placeholder="" /> 
								{ errors.imc_number && <p className="form-error">This field is required</p> }
							</div>
							<div className="form__row custom-select column--2">
								<label className="form__label" htmlFor="user_type">I am a: </label>
								<CustomSelect required defaultValue={null} name="user_type" control={control} options={userTypes} />
								{ errors.user_type && <p className="form-error">This field is required</p> }
							</div>
						</div>
						<div className="form__row form__row--columns">
							<div className="form__row column--2 custom-select">
								<label className="form__label" htmlFor="county">County</label>
								<CustomSelect required defaultValue={null} name="county" control={control} options={countyTypes} />
								{ errors.county && <p className="form-error">This field is required</p> }
							</div>
							<div className="form__row column--2">
								<label className="form__label" htmlFor="phone">Telephone</label>
								<input { ...register('phone', { required: true }) }  className="form__input" id="uPhone" type="phone" placeholder="" /> 
								{ errors.phone && <p className="form-error">This field is required</p> }
							</div>
						</div>
						<div className="form__row column">
							<label className="form__label" htmlFor="work_address">Work address</label>
							<input { ...register('work_address', { required: true }) } className="form__input" id="uWAdress" type="text" placeholder="" /> 
							{ errors.work_address && <p className="form-error">This field is required</p> }
						</div>
						<div className="form__row form__row--radio">
							<label className="form__label">Do you hold other post graduate qualifications in the area of Dermatology?</label>
							<input onChange={() => setChecked('Yes')} checked={checked == 'Yes'} className="form__radio" id="uPostYes" name="uPost" type="radio" placeholder="" value="yes" /> 
							<label className="form__label form__label--radio"  htmlFor="uPostYes">Yes</label>
							<input onChange={() => setChecked('No')} checked={checked == 'No'} className="form__radio" id="uPostNo" name="uPost" type="radio" placeholder="" value="no" /> 
							<label className="form__label form__label--radio"  htmlFor="uPostNo">No</label>
						</div>
						<div className={`form__row custom-select ${ checked == 'Yes' ? '' : 'disabled' } `}>
							{ checked == 'Yes' ?
								 <>
								 <label className="form__label" htmlFor="other_qualification">Other qualifications</label>
								 	<CustomSelect defaultValue={null} required name="other_qualification" control={control} options={otherQualifications} />
									 { errors.other_qualification && <p className="form-error">This field is required</p> }
								 </>
								: null
							 }
						</div>
						
						<div className="form__row form__row--radio">
							<input type="checkbox" { ...register('accepted', { required: true }) } className="form__checkbox" id="Policy"  />
							<label className={`form__label form__label--checkbox ${errors.accepted && 'error'}`} htmlFor="Policy">I have read and understand the <Link target="_blank" to="/privacy-policy">Privacy Policy</Link></label>
						</div>
						<div className="form__row form__row--tac form__row--submit">
							<input className="form__submit btns-blue" type="submit" value="Create my account"  />
						</div>
						<div className="form__row form__row-steps form__row--tac">
							<ul className="steps__list">
								<li className="list__item"><span></span></li>
								<li className="list__item active"><span></span></li>
							</ul>
						</div>
					</form>
				</div>
			</div>
		</section>
		</main>
  )
}

export default RegisterStepTwo
