import React, { useState } from 'react'
import _ from 'lodash';

const Search = ({ defaultValue, onSearch, onSearchClear, placeholder }) => {
  const [search, setSearch] = useState(defaultValue)

  const handleSearch = _.debounce((value) => {
    if(value?.length) {
      onSearch(value)
    } else {
      onSearchClear()
    }
  },
  500,
);

  return (
    <section className="search">
      <div className="search__content">
        <div className="search__form">
          <form className="form" onSubmit={e => e.preventDefault()}>
            <div className="form__row form__row--search">
              <input value={search} onChange={e => {
                setSearch(e.target.value)
                handleSearch(e.target.value)
              } } className="form__input form__input--search" type="text" name="wSearch" placeholder={placeholder || `Search webinar by title`}/>
              <input onSubmit={e => e.preventDefault()} className="form__submit form__submit--search" type="submit" name="Submit" value="" />
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Search
