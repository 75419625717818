import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '@actions'
import { useForm } from "react-hook-form";
import SuccessPopup from '@components/SuccessPopup';

const Forgot = () => {
  const { error }  = useSelector(state => state.authReducer)
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch()
  
  const onSubmit = data => dispatch(authActions.forgotPassword(data.email));

  return (
    <main class="content">
				<section class="profile profile--page">
					<div class="profile__content">
						<h1 class="page__title">Forgot Password</h1>
							<form class="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="form__row">
									<label className="form__label" for="email">Email address</label>
									<input { ...register('email', { required: true }) } className="form__input" id="uEmail"  type="email" placeholder="" /> 
									 { errors.email && <p className="form-c">Email is required</p> }
								</div>
                <div className="form__row form__row--tac form__row--submit">
									<input className="form__submit btns-opacity" type="submit" value="Recover" />
                  { error && <p className="form-error">{error}</p> }
								</div>
              </form>
					</div>
				</section>
				<SuccessPopup />
			</main>
  )
}

export default Forgot
