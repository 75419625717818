import React from 'react'

const ContentWrapper = ({ children }) => {
  return (
    <div className="content__wrapper">
      { children }
    </div>
  )
}

export default ContentWrapper
