import React from "react";
import { config } from "@config";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

const PresentationCard = ({ presentation, handleOpen }) => {
  const { user } = useSelector((state) => state.authReducer);
  const {
    id,
    title,
    description,
    speaker_name,
    speaker_title,
    speaker_county,
    file,
  } = presentation;

  return (
    <div className='list__item'>
      <div className='card card--presentation'>
        <div className='card__top'>
          <div className='webinar__date presentations'>
            <svg className='icons svg i16x16'>
              <use xlinkHref='#i-calendar'></use>
            </svg>
            { presentation?.start_at ? moment(presentation?.start_at).format("DD MMMM, YYYY") : 'To be decided' }
          </div>
        </div>
        <div className='presentation__speaker'>{speaker_name}</div>
        <div className='presentation__location'>{speaker_county}</div>
        <div className='speaker__post'>{speaker_title}</div>
        <div className='presentation__title'>{title}</div>
        <div className='presentation__description'>
          <p>{description}</p>
        </div>
        <div className='presentation__btns'>
          {user && user.subscription_status == "active" ? (
            <a
              href={`${config.REACT_APP_IMAGE_URL}${file}`}
              className='btns btns-opacity'
              data-toggle='class'
              data-target='#popups'
              data-classes='presentations'
              target='_blank'
              download
            >
              Download
            </a>
          ) : (
            <a
              onClick={handleOpen}
              className='btns btns-opacity'
              data-toggle='className'
              data-target='#popups'
              data-classes='presentations'
            >
              Download
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default PresentationCard;
