import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__content'>
        <div className='footer__block'>
          <div className='footer__contacts'>
            <div className='contacts__label'>Contact us :</div>
            <div className='contacts__name'>Elaine Martin</div>
            <a href='mailto:pcdsimembers@gmail.com' className='contacts__email'>
              pcdsimembers@gmail.com
            </a>
          </div>
        </div>
        <div className='footer__block'>
          <div className='footer__contacts'>
            <div className='contacts__label'>
              RCN: 20072773{" "}
            </div>
          </div>
        </div>
        <div className='footer__block'>
          <div className='footer__menu menu'>
            <ul className='menu__list'>
              <li className='list__item'>
                <Link className='link' to='/privacy-policy'>
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className='footer__copyrights'>
            © PCDSI {new Date().getFullYear()}. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
