import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { webinarsActions } from "@actions";

import Pagination from "@components/Pagination";
import PresentationCard from "./PresentationCard";
import Search from "../Search";
import Popup from "@components/Popup";

const Presentations = () => {
  const [open, setOpen] = useState(false);
  const { presentations, presentationMeta } = useSelector(
    (state) => state.webinarsReducer
  );
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(webinarsActions.getPresentations(presentationMeta, true));
  }, []);

  const handlePageChange = (page) =>
    dispatch(
      webinarsActions.getPresentations(
        { ...presentationMeta, page: page },
        true
      )
    );

  const handleSearch = (value) =>
    dispatch(
      webinarsActions.getPresentations(
        { ...presentationMeta, query: value },
        true
      )
    );
  const handleClearSearch = () =>
    dispatch(
      webinarsActions.getPresentations({ ...presentationMeta, query: "" }, true)
    );

  return (
    <>
      <main class='content'>
        <section class='presentations presentations--page'>
          <div class='presentations__content'>
            {user?.id ? (
              <div class='disclaimer'>
                <p>
                  Due to patient confidentiality some speakers have removed
                  clinical photos from their slides. Please be aware information
                  in these slides may have changed since they were published on
                  this website.  
                </p>
                <p>
                  Information or recommendations in the slides are that of the
                  individual speaker, rather than the PCDSI
                </p>
              </div>
            ) : null}
            <h1 class='page__title'>Presentations</h1>
            {/* <!-- Search --> */}
            <Search
              onSearch={handleSearch}
              onSearchClear={handleClearSearch}
              defaultValue={presentationMeta.query}
              placeholder='Search presentation by title'
            />
            {/* <!-- /Search --> */}

            {presentations?.length ? (
              <div class='presentations__list'>
                {presentations.map((presentation) => (
                  <PresentationCard
                    handleOpen={() => setOpen(true)}
                    presentation={presentation}
                    key={presentation?.id}
                  />
                ))}
              </div>
            ) : (
              <div className='webinars__empty'>
                <div className='empty__text'>
                  <p>Presentations will be published here soon</p>
                </div>
                <div className='empty__icons'>
                  <i className='icon'></i>
                </div>
              </div>
            )}

            <Pagination
              totalPages={presentationMeta?.total_pages}
              currentPage={presentationMeta.page}
              onPageChange={handlePageChange}
            />
          </div>
        </section>
      </main>
      <Popup
        placeholder='To access this presentation, you must be a member of the PCDSI'
        open={open}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};

export default Presentations;
