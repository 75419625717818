import React from 'react'
import ContentWrapper from '@components/Markup/ContentWrapper'
import PrivacyPolicy from '../components/PrivcayPolicy'

const PrivacyPolicyPage = () => {
  return (
    <ContentWrapper>
      <PrivacyPolicy />
    </ContentWrapper>
  )
}

export default PrivacyPolicyPage
