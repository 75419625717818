import React from 'react'
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

const CustomSelect = ({ control, name, options, defaultValue, disabled = false, required = false } ) => {

  const capitalizeFirstLetter = string => string?.charAt(0)?.toUpperCase() + string?.slice(1);

  return (
    <Controller
        control={control}
        name={name}
        rules={{ required: required }}
        defaultValue={defaultValue ? { value: defaultValue, label: capitalizeFirstLetter(defaultValue?.split('_').join(' '))} : null}
        render={({ field: { onChange, value, name, ref } }) => (
            <Select
                inputRef={ref}
                options={options}
                onChange={val => onChange(val.value)}
                isDisabled={disabled}
                defaultValue={value}
            />
        )}
    />
  )
}

export default CustomSelect
