import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { globalActions } from '@actions'

const SuccessPopup = ({ action, actionPlaceholder = 'OK'  }) => {
  const { show, message } = useSelector(state => state.globalReducer.successPopup)
  const { user } = useSelector(state => state.authReducer)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(globalActions.toggleSuccessModal({ show: false, message: '' }))
  }

  return (
    <section id="popups" className={`popup ${show ? 'presentations show' : ''}`}>
		<div className="popup__overlay">
			<div className="popup__block popup--presentations">
				<div className="block__top">
					<button className="btns btns-close" onClick={handleClose}></button>
				</div>
				<div className="block__title">{ message }</div>
        <div className="block__btns">
            <a onClick={action ? action : handleClose} className="btns btns-opacity">                
              {actionPlaceholder}
            </a>
          </div>
			</div>
		</div>
	</section>
  )
}

export default SuccessPopup
