import React from 'react'
import ContentWrapper from '@components/Markup/ContentWrapper'
import ForgotPassword from '@components/ForgotPassword'

const ForgotPasswordPage = () => {
  return (
    <ContentWrapper>
      <ForgotPassword />
    </ContentWrapper>
  )
}

export default ForgotPasswordPage
