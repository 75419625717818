export default {
  GET_WEBINARS: 'GET_WEBINARS',
  GET_WEBINARS_SUCCESS: 'GET_WEBINARS_SUCCESS',
  GET_WEBINARS_ERROR: 'GET_WEBINARS_ERROR',
  GET_PRESENTATIONS: 'GET_PRESENTATIONS',
  GET_PRESENTATIONS_SUCCESS: 'GET_PRESENTATIONS_SUCCESS',
  GET_PRESENTATIONS_ERROR: 'GET_PRESENTATIONS_ERROR',
  GET_WEBINAR: 'GET_WEBINAR',
  GET_WEBINAR_SUCCESS: 'GET_WEBINAR_SUCCESS',
  GET_WEBINAR_ERROR: 'GET_WEBINAR_ERROR',
  GET_PRESENTATION: 'GET_PRESENTATION',
  GET_PRESENTATION_SUCCESS: 'GET_PRESENTATION_SUCCESS',
  GET_PRESENTATION_ERROR: 'GET_PRESENTATION_ERROR',
  GET_LINKS: 'GET_LINKS',
  GET_LINKS_SUCCESS: 'GET_LINKS_SUCCESS',
  GET_LINKS_ERROR: 'GET_LINKS_ERROR'
}