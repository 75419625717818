import { WEBINARS_ACTIONS } from '@constants';

export const getWebinars = (meta, replace) => ({
  type: WEBINARS_ACTIONS.GET_WEBINARS,
  payload: { meta, replace }
});

export const getWebinarsSuccess = (webinars, total_pages, replace = false) => ({
  type: WEBINARS_ACTIONS.GET_WEBINARS_SUCCESS,
  payload: { webinars, total_pages, replace }
});

export const getWebinarsError = (error) => ({
  type: WEBINARS_ACTIONS.GET_WEBINARS_ERROR,
  payload: { error }
});

export const getPresentations = (meta, replace) => ({
  type: WEBINARS_ACTIONS.GET_PRESENTATIONS,
  payload: { meta, replace }
});

export const getPresentationsSuccess = (presentations, total_pages, replace = false) => ({
  type: WEBINARS_ACTIONS.GET_PRESENTATIONS_SUCCESS,
  payload: { presentations, total_pages, replace }
});

export const getPresentationsError = (error) => ({
  type: WEBINARS_ACTIONS.GET_PRESENTATIONS_ERROR,
  payload: { error }
});

export const getWebinar = (id) => ({
  type: WEBINARS_ACTIONS.GET_WEBINAR,
  payload: { id }
});

export const getWebinarSuccess = (webinar) => ({
  type: WEBINARS_ACTIONS.GET_WEBINAR_SUCCESS,
  payload: { webinar }
});

export const getWebinarError = (error) => ({
  type: WEBINARS_ACTIONS.GET_WEBINAR_ERROR,
  payload: { error }
});

export const getPresentation = (id) => ({
  type: WEBINARS_ACTIONS.GET_PRESENTATION,
  payload: { id }
});

export const getPresentationSuccess = (presentation) => ({
  type: WEBINARS_ACTIONS.GET_PRESENTATION_SUCCESS,
  payload: { presentation }
});

export const getPresentationError = (error) => ({
  type: WEBINARS_ACTIONS.GET_PRESENTATION_ERROR,
  payload: { error }
});

export const getLinks = () => ({
  type: WEBINARS_ACTIONS.GET_LINKS,
  payload: {}
});

export const getLinksSuccess = (links) => ({
  type: WEBINARS_ACTIONS.GET_LINKS_SUCCESS,
  payload: { links }
});

export const getLinksError = (error) => ({
  type: WEBINARS_ACTIONS.GET_LINKS_ERROR,
  payload: { error }
});