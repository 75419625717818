import {fork} from 'redux-saga/effects';
import {watchAuthSagas} from './authSagas';
import {watchWebinarsSagas} from './webinarsSagas';
import {watchProfileSagas} from './profileSagas';
import {watchPaymentsSagas} from './paymentsSagas';

export default function* rootSaga() {
  yield fork(watchAuthSagas);
  yield fork(watchWebinarsSagas);
  yield fork(watchProfileSagas);
  yield fork(watchPaymentsSagas);
  // yield fork(watchGlobalSagas);
  // yield fork(watchCarsSagas);
  // yield fork(watchMessagingSagas);
  // yield fork(watchCarFilterSagas);
  // yield fork(watchNotificationsSagas);
}
