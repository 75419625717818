import React from 'react'

const Pagination = ({ totalPages, currentPage, onPageChange }) => {

  const elements = [];

  for (let i = 1; i < totalPages + 1; i++) {
    elements.push((
      <li className="list__item" key={i} onClick={() => onPageChange(i)}>
        <a className={`link ${ i == currentPage ? 'link--current' : '' }`}>{i}</a>
      </li>
    ));
  }

  return (
    <section className="pagination">
      <div className="pagination__content">
        <ul className="pagination__list">
          { totalPages > 1 && elements }
        </ul>
      </div>
    </section>
  )
}

export default Pagination
