import React from 'react'
import ContentWrapper from '@components/Markup/ContentWrapper'
import Login from '@components/Login'

const LoginPage = () => {
  return (
    <ContentWrapper>
      <Login />
    </ContentWrapper>
  )
}

export default LoginPage
