import React from 'react'
import ContentWrapper from '@components/Markup/ContentWrapper'
import Profile from '@components/Profile'

const ProfilePage = () => {
  return (
    <ContentWrapper>
      <Profile />
    </ContentWrapper>
  )
}

export default ProfilePage
