import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { webinarsActions } from "@actions";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import moment from "moment";
import Popup from "@components/Popup";

const WebinarDetail = () => {
  const { webinar } = useSelector((state) => state.webinarsReducer);
  const { user } = useSelector((state) => state.authReducer);
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(webinarsActions.getWebinar(id));
  }, [id]);

  return webinar ? (
    <>
      <main className='content'>
        {/* <!-- Webinar --> */}
        <article className='webinars webinars--article'>
          <div className='webinars__content'>
            <h1 className='page__title'>{webinar?.title}</h1>
            <div className='webinar__datetime'>
              <div className='webinar__date'>
                <svg className='icons svg i16x16'>
                  <use xlinkHref='#i-calendar'></use>
                </svg>
                {moment(webinar?.date_started).format("DD MMMM, YYYY")}
              </div>
              <div className='webinar__time'>
                <svg className='icons svg i16x16'>
                  <use xlinkHref='#i-watches'></use>
                </svg>
                {webinar?.time_started}
              </div>
            </div>
            <div className='webinar__content'>
              <div className='webinar__description'>
                <div className='block__title'>Description</div>
                <p>{webinar?.description}</p>
              </div>
              <div className='webinar__speakers'>
                <div className='block__title'>Speakers</div>
                <div className='speakers__list'>
                  {webinar?.speaker?.map((speaker) => (
                    <div className='list__item'>
                      <div className='card'>
                        <div className='speaker__location'>
                          {speaker?.county}
                        </div>
                        <div className='speaker__name'>{speaker?.name}</div>
                        <div className='speaker__post'>{speaker?.title}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {webinar.is_recorded ? (
              <div className='webinar__btns'>
                {user ? (
                  user?.subscription_status == "active" ? (
                    <a
                      href={webinar?.vimeo_link}
                      target='_blank'
                      className='btns btns-blue'
                      data-toggle='className'
                      data-target='#popups'
                      data-classNamees='webinar'
                    >
                      View Recorded Webinar
                    </a>
                  ) : (
                    <Link
                      to='/subscriptions'
                      className='btns btns-blue'
                      data-toggle='className'
                      data-target='#popups'
                      data-classNamees='webinar'
                    >
                      View Recorded Webinar
                    </Link>
                  )
                ) : (
                  <Link
                    to='/login'
                    className='btns btns-blue'
                    data-toggle='className'
                    data-target='#popups'
                    data-classNamees='webinar'
                  >
                    Log In To Access Webinar
                  </Link>
                )}
              </div>
            ) : (
              <div className='webinar__btns'>
                {user ? (
                  user?.subscription_status == "active" ? (
                    <a
                      href={webinar?.webinar_link}
                      target='_blank'
                      className='btns btns-blue'
                      data-toggle='className'
                      data-target='#popups'
                      data-classNamees='webinar'
                    >
                      Join the Webinar
                    </a>
                  ) : (
                    <Link
                      to='/subscriptions'
                      className='btns btns-blue'
                      data-toggle='className'
                      data-target='#popups'
                      data-classNamees='webinar'
                    >
                      Join the Webinar
                    </Link>
                  )
                ) : (
                  <Link
                    to='/login'
                    className='btns btns-blue'
                    data-toggle='className'
                    data-target='#popups'
                    data-classNamees='webinar'
                  >
                    Log In To Access Webinar
                  </Link>
                )}
              </div>
            )}
          </div>
        </article>
        {/* <!-- /Webinars --> */}
      </main>
      <Popup open={open} handleClose={() => setOpen(false)} />
    </>
  ) : (
    <p>Loading...</p>
  );
};

export default WebinarDetail;
