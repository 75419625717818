import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { paymentsActions } from '@actions'

const CancelPopup = ({ open = false, handleClose }) => {
  const dispatch = useDispatch()

  const handleCancelSubscription = () => {
    dispatch(paymentsActions.cancelSubscription())
  }

  return (
    <section id="popups" className={`popup ${open ? 'presentations show' : ''}`}>
		<div className="popup__overlay">
			<div className="popup__block popup--presentations">
				<div className="block__top">
					<button className="btns btns-close" onClick={handleClose}></button>
				</div>
				<div className="block__title">Are you sure you want to cancel the subscribtion?</div>
          <div className="block__btns">
            <a  onClick={handleCancelSubscription} className="btns btns-opacity" style={{ marginRight: '10px' }}>Yes</a>
            <a onClick={handleClose} className="btns btns-opacity">No</a>
          </div>

			</div>
		</div>
	</section>
  )
}

export default CancelPopup
