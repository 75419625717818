import React from 'react'
import ContentWrapper from '@components/Markup/ContentWrapper'
import Websites from '../components/Websites'

const WebsitesPage = () => {
  return (
    <ContentWrapper>
      <Websites />
    </ContentWrapper>
  )
}

export default WebsitesPage
