import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { authActions } from '@actions'
import { useWindowSize } from '@hooks/useWindowSize'
import './Header.css'

const Header = () => {
  const { user } = useSelector(state => state.authReducer)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [mobileOpen, setMobileOpen] = useState(false)
  const size = useWindowSize()

  const handleOpen = () =>  { 
    setOpen(false) 
    setMobileOpen(false)
  }
  const handleLogout = () => {
    dispatch(authActions.logout())
    handleOpen()
  } 

  return (
    <header className="header">
      <div className="header__content">
        <div className="header__logo">
          <NavLink className="logo" to="/">
          </NavLink>
        </div>
        <div className="header__btns">
          <button className="btns btns-menu" onClick={() => setMobileOpen(true)}>
            <span></span>
            <span></span>
          </button>
        </div>
        <div className="header__block--m" style={size.width < 1100 ? { right: mobileOpen ? '0' : '-100%', maxWidth: 'unset', maxHeight: 
      'unset' } : {}}>
          <div className="block__top">
            <div className="header__logo">
              <NavLink className="logo" to="/">
              </NavLink>
            </div>
            <div className="header__btns">
              <button className="btns btns-close" onClick={() => setOpen(prevOpen => !prevOpen)}>
                <span></span>
                <span></span>
              </button>
            </div>				
          </div>
          <nav className="header__nav">
            <div className="menu">
              <ul className="menu__list">
                <li className="list__item">
                  <NavLink exact onClick={handleOpen} className="link" to="/" title="Home">Home</NavLink>
                </li>
                <li className="list__item">
                  <NavLink onClick={handleOpen} className="link @@membership" to="/membership" title="Membership">Membership</NavLink>
                </li>
                <li className="list__item">
                  <NavLink onClick={handleOpen} className="link @@upcoming" to="/upcoming">Upcoming Meetings</NavLink>
                </li>
                <li className="list__item">
                  <NavLink onClick={handleOpen} className="link @@webinars" to="/webinars" title="Webinars">Webinars</NavLink>
                </li>
                <li className="list__item">
                  <NavLink onClick={handleOpen} className="link @presentations" to="/presentations" title="Presentations">Presentations</NavLink>
                </li>
                <li className="list__item">
                  <NavLink onClick={handleOpen} className="link @@websites" to="/websites" title="Useful Websites">Useful Websites</NavLink>
                </li>
              </ul>
            </div>
          </nav>
          <div className="header__auth">

          { user ?
          <div className="authorized">
					<button className={`btns btns-auth ${open ? 'btns-close' : '' }`} onClick={() => setOpen(prevOpen => !prevOpen)}>
						<span></span>
						<span></span>
					</button>
					<div className="menu" style={{ top: open ? 'unset' : '-1000%' }}>
						<ul className="menu__list">
							<li className="list__item">
								<NavLink onClick={handleOpen} className="link @@profile" to="/profile" title="Profile">
									<svg className="icons svg i18x18">
										<use xlinkHref="#i-portrait"></use>
									</svg>
									Edit my profile
								</NavLink>
							</li>
							<li className="list__item">
								<NavLink onClick={handleOpen} className="link @@subscription" to="/subscriptions" title="Subscription">
									<svg className="icons svg i18x18">
										<use xlinkHref="#i-credit_check"></use>
									</svg>
									My Subscription
								</NavLink>
							</li>
							<li className="list__item">
								<a className="link logout" onClick={handleLogout} title="Log out">
									<i className="icons bg i24x24 i-logout"></i>
									Log Out
								</a>
							</li>
						</ul>
					</div>
				</div>
          :
            <div className="notauthorized">
            <div className="menu">
              <ul className="menu__list">
                <li className="list__item">
                  <NavLink onClick={handleOpen} className="btns btns-trprnt" to="/login" title="Log In">Log in</NavLink>
                </li>
                <li className="list__item">
                  <NavLink onClick={handleOpen} className="btns btns-opacity" to="/register" title="Sign Up">Sign up</NavLink>
                </li>
              </ul>
            </div>
          </div>
           }
            
            
            
            
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
