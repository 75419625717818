import React from 'react'
import ContentWrapper from '@components/Markup/ContentWrapper'
import Register from '../components/Register'

const RegisterPage = () => {
  return (
    <ContentWrapper>
      <Register />
    </ContentWrapper>
  )
}

export default RegisterPage
