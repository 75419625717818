import {combineReducers} from 'redux';
import {authReducer} from './auth';
import {globalReducer} from './global';
import {webinarsReducer} from './webinars';
import {profileReducer} from './profile';
import {messagingReducer} from './messages';
import {paymentsReducer} from './payments';
import {notificationsReducer} from './notifications';
import { connectRouter } from 'connected-react-router'

export default (history) => combineReducers({
  router: connectRouter(history),
  authReducer,
  webinarsReducer,
  profileReducer,
  paymentsReducer,
  globalReducer,
  // carsReducer,
  // messagingReducer,
  // carFilterReducer,
  // notificationsReducer,
});
