import React from "react";
import ContentWrapper from "@components/Markup/ContentWrapper";
import Webinars from "@components/Webinars";

const WebinarsPage = () => {
  return (
    <ContentWrapper>
      <Webinars />
    </ContentWrapper>
  );
};

export default WebinarsPage;
