import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { webinarsActions } from "@actions";
import { INITIAL_STATE } from "@redux/constants";

import welcomImg from "@img/welcome.png";
import welcomImg2x from "@img/welcome@2x.png";
import welcomImg3x from "@img/welcome@3x.png";

import welcomImgMob from "@img/welcome_m.png";
import welcomImgMob2x from "@img/welcome_m@2x.png";
import welcomImgMob3x from "@img/welcome_m@3x.png";

import "./Landing.css";
import { NavLink, Link } from "react-router-dom";

import WebinarCard from "@components/Webinars/WebinarCard";

const Landing = () => {
  const { user } = useSelector((state) => state.authReducer);
  const { webinars } = useSelector((state) => state.webinarsReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      webinarsActions.getWebinars(INITIAL_STATE.WebinarsInitialState.meta)
    );
  }, []);

  return (
    <div className='content__wrapper'>
      <main className='content'>
        <section className='welcome'>
          <div className='welcome__content'>
            {!user && (
              <>
                <a href='https://abbey.eventsair.com/pcdsi-2025/registration'>
                  <div class='welcome__banner-container'>
                    <div class='welcome__desc'>
                      <h1>PCDSI 2025</h1>
                      <button class='meeting-date'>27-29 March 2025</button>
                      <div class='split-line'></div>
                      <h4>
                        Great Southern Killarney for the 27-29 March 2025
                      </h4>
                    </div>
                    <div className='welcome__banner'></div>
                  </div>
                </a>
                <a
                  className='btns btns-blue banner-btn'
                  href='https://abbey.eventsair.com/pcdsi-2025/programme'
                >
                  Programme and Conference Information
                </a>
                <a
                  className='btns btns-blue banner-btn'
                  href='https://abbey.eventsair.com/pcdsi-2025/registration'
                >
                  Register Now
                </a>
              </>
            )}
            <div className='welcome__content-wrapper'>
              <figure className='welcome__image'>
                <img
                  className='image image--mobile'
                  style={{ transform: "scale(0.9)" }}
                  src={welcomImg}
                  srcset={`${welcomImg2x} 2x, ${welcomImg3x} 3x`}
                  alt='Welcome'
                />
                <img
                  className='image image--desktop'
                  src={welcomImg}
                  srcset={`${welcomImg2x} 2x, ${welcomImg3x} 3x`}
                  alt='Welcome'
                />
              </figure>
              <div className='welcome__block'>
                <div className='welcome__subtitle'>Welcome to the</div>
                <h1 className='welcome__title'>
                  Primary Care Dermatology Society
                </h1>
                <div className='welcome__text'>
                  <p>
                    The PCDSI has been in existence for over 20 years with Dr
                    Hilda O Shea, Dr David Buckley and Dr Johnny Loughnane as
                    the original founding members. Over the last 20 years the
                    society has steadily grown. Its aim is to promote education
                    in dermatology for general practitioners for the benefit of
                    the general community.
                  </p>

                  {/* <p>
                    <Link to='/upcoming'>
                      The society will be hosting its 2024 meeting on the 11th, 12th and 13th of April.
                    </Link>
                  </p> */}

                  <p>
                    Annual membership of the society costs € 40. Register on
                    this site to pay the annual membership fee.
                  </p>
                </div>
                <div className='welcome__btns'>
                  {user ? (
                    <NavLink to='/subscriptions' className='btns btns-blue'>
                      Join Us
                    </NavLink>
                  ) : (
                    <NavLink to='/register' className='btns btns-blue'>
                      Join Us
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='webinars webinars--block'>
          <div className='webinars__content'>
            <h2 className='block__title'>Webinars</h2>
            {webinars?.length ? (
              <div className='webinars__list'>
                {webinars.map((webinar) => (
                  <WebinarCard webinar={webinar} key={webinar?.id} />
                ))}
              </div>
            ) : (
              // no webinars
              <div className='webinars__empty'>
                <div className='empty__text'>
                  <p>
                    Details of upcoming webinars will be published here soon
                  </p>
                </div>
                <div className='empty__icons'>
                  <i className='icon'></i>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
    </div>
  );
};

export default Landing;
