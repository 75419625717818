import React from 'react'
import ContentWrapper from '@components/Markup/ContentWrapper'
import Landing from '@components/Landing'

const LandingPage = () => {
  return (
    <ContentWrapper>
      <Landing />
    </ContentWrapper>
  )
}

export default LandingPage
