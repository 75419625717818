import React, { useEffect } from 'react'
import Header from '@components/Header'
import Footer from '@components/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '@actions'
import { getToken } from '@services/LocalStorage'

const PageWrapper = ({ children }) => {
  const { user } = useSelector(state => state.authReducer)
  const token = getToken()
  const dispatch = useDispatch()

  useEffect(() => {
   dispatch(authActions.isAuthenticated())
  }, [user?.id])

  return (
    <>
      <Header />
      {children}
      <Footer />
    </> 
  )
}

export default PageWrapper
