export const AuthInitialState = {
  user: null,
  error: "",
  step: 0,
};

export const GlobalInitialState = {
  showLoginModal: false,
  showRegisterModal: false,
  reportModal: {
    show: false,
    reportUserId: null,
  },
  successPopup: {
    show: false,
    message: "",
  },
  loading: false,
  successMessage: "",
  error: "",
};

export const WebinarsInitialState = {
  // posts: [],
  // post: null,
  error: "",
  webinars: [],
  presentations: [],
  webinar: null,
  presentation: null,
  clinic_websites: [],
  patients_websites: [],

  meta: {
    page: 1,
    perPage: 6,
    query: "",
    total_pages: null,
    scope: "recorded",
  },

  presentationMeta: {
    page: 1,
    perPage: 6,
    query: "",
    total_pages: null,
  },
};

export const MESSAGING_STORE = {
  chats: [],
  chats_count: 0,
  chat: {},
  messages: [],
  message: {},
  messages_length: 0,
  badge_count: 0,
  showMessagePopup: false,
  cable: null,
  cbs: {},
  meta: {
    page: 1,
    perPage: 10,
    search: "",
  },
};

export const PaymentsInitialState = {
  isProcessing: false,
  paymentIntent: null,
  donateModal: {},
  donateModalVisible: false,
  paymentMessage: "",
  transactions: [],
};

export const INFORMATION_STORE = {
  resources: [],
  resourcesMeta: {
    page: 1,
    perPage: 10,
    search: "",
  },
  article: {},
  articles: [],
  articlesMeta: {
    page: 1,
    perPage: 10,
    search: "",
  },
  feed: [],
  feedMeta: { page: 1, perPage: 10 },
};

export const ProfileInitialState = {
  users: [],
  user: null,
  error: "",
  meta: {
    page: 1,
    perPage: 4,
    total_pages: null,
    search: "",
  },
  followers: [],
  following: [],
  followersMeta: {
    page: 1,
    perPage: 10,
  },
  followingMeta: {
    page: 1,
    perPage: 10,
  },
  ratingUsers: [],
  ratingUsersMeta: {
    page: 1,
    perPage: 10,
  },
  petsRatingUsers: [],
  petsRatingUsersMeta: {
    page: 1,
    perPage: 10,
  },
  servicesRatingUsers: [],
  servicesRatingUsersMeta: {
    page: 1,
    perPage: 10,
  },
  ratings: [],
  followers: [],
  following: [],
  blackList: [],
  donations: [],
  currentUser: null,
};

export const SERVICES_STORE = {
  serviceTypes: [],
  services: [],
  meta: {
    page: 1,
    perPage: 20,
  },
  servicesMeta: {
    page: 1,
    perPage: 10,
    selectedServiceTypes: [],
    search: "",
    lat: null,
    lon: null,
  },
};

export const CAR_FILTER_STORE = {
  makes: [],
  models: [],
  generations: [],
  series: [],
  trims: [],
  make_id: null,
  filter: {
    make: null,
    model: null,
    year_from: null,
    year_to: null,
    price_from: null,
    price_to: null,
  },
};

export const NOTIFICATIONS_STORE = {
  meta: {
    page: 1,
    per_page: 10,
  },
  notifications: [],
};
