import React from 'react'
import ContentWrapper from '@components/Markup/ContentWrapper'
import Forgot from '@components/Forgot'

const ForgotPage = () => {
  return (
    <ContentWrapper>
      <Forgot />
    </ContentWrapper>
  )
}

export default ForgotPage
