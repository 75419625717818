import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import memberImg from '@img/bursaries.png'
import memberImg2x from '@img/bursaries@2x.png'
import memberImg3x from '@img/bursaries@3x.png'

import memberImgMob from '@img/bursaries_m.png'
import memberImgMob2x from '@img/bursaries_m@2x.png'
import memberImgMob3x from '@img/bursaries_m@3x.png'


const Membership = () => {
	const { user } = useSelector(state => state.authReducer)

  return (
    <main class="content">
				{/* <!-- Membership --> */}
				<section class="membership membership--page">
					<div class="membership__content">
						{/* <!-- Top --> */}
						<section class="membership__top">
							{/* <h2 class="page__subtitle">Membership in</h2> */}
							<h1 class="page__title">Primary Care <br/> Dermatology Society</h1>
							<div class="top__text">
								<p>
								Annual membership of the society costs € 40. Register on this site to pay the annual membership fee.
								</p>
							</div>
							<div class="top__btns">
								{ user ?
										<NavLink to="/subscriptions" className="btns btns-blue">Join Us</NavLink>
									: <NavLink to="/register" className="btns btns-blue">Join Us</NavLink>
								}
							</div>
						</section>
						{/* <!-- /Top --> */}
						{/* <!-- Benefits --> */}
						<section class="membership__benefits">
							<div class="section__title">Benefits of PCDSI membership</div>
							<div class="benefits__list">
								<div class="list__item">
									<div class="card">
										<div class="benefit__number">
											1
										</div>
										<div class="benefit__text">
											Reduced registration fees for the annual PCDSI meeting
										</div>
									</div>
								</div>
								<div class="list__item">
									<div class="card">
										<div class="benefit__number">
											2
										</div>
										<div class="benefit__text">
											Reduced registration fees for the annual Primary Care Surgical Association meeting
										</div>
									</div>
								</div>
								<div class="list__item">
									<div class="card">
										<div class="benefit__number">
											3
										</div>
										<div class="benefit__text">
											Access to resources and links to online websites
										</div>
									</div>
								</div>
								<div class="list__item">
									<div class="card">
										<div class="benefit__number">
											4
										</div>
										<div class="benefit__text">
											Opportunity to present cases at the annual PCDSI meeting
										</div>
									</div>
								</div>
								<div class="list__item">
									<div class="card">
										<div class="benefit__number">
											5
										</div>
										<div class="benefit__text">
										Opportunity to apply for a bursary to assist in the funding of approved educational courses including certificates, diplomas and masters in the area of dermatology (excludes meetings; restrictions apply)
										</div>
									</div>
								</div>
								{/* <div class="list__item">
									<div class="card">
										<div class="benefit__number">
											6
										</div>
										<div class="benefit__text">
											Access to an upcoming online GP discussion forum discussing dermatology cases
										</div>
									</div>
								</div> */}
							</div>
						</section>
						{/* <!-- /Benefits --> */}
						{/* <!-- Bursaries --> */}
						<section class="membership__bursaries">
							<div class="section__title">Bursaries</div>
							<div class="bursaries__text">
								<p>
									Bursaries are available to members in good standing who have attended the previous 2 years annual meeting.<br/> Two bursaries may be given out annually pending availability and approval from the committee. 
								</p>
								<p>
									Maximum bursary -  50% of the cost of the course fee (travel/accomodation not included) up to a maximum of €2500.<br/> Bursary recipients would be expected to present interesting cases or topics at the following PCDSI annual meeting.
								</p>
							</div>
							<figure class="bursaries__image">
								<img class="image image--mobile" src={memberImg} srcset={`${memberImg2x} 2x, ${memberImg3x} 3x`} alt="Bursaries" />
								<img class="image image--desktop" src={memberImg} srcset={`${memberImg2x} 2x, ${memberImg3x} 3x`} alt="Bursaries" />
							</figure>
						</section>
						{/* <!-- /Bursaries --> */}
						{/* <!-- Members --> */}
						<section class="membership__members">
							<div class="section__title">Committee Members</div>
							<div class="members__list">
								<div class="list__item">
									<div class="card">
										<div class="member__post">Chairperson</div>
										<div class="member__name">Dr Siobhan Twohig</div>
									</div>
								</div>
								<div class="list__item">
									<div class="card">
										<div class="member__post">Committee Member</div>
										<div class="member__name">Dr Karen Reidy </div>
									</div>
								</div>
								<div class="list__item">
									<div class="card">
										<div class="member__post">Committee Member</div>
										<div class="member__name">Dr David Buckley</div>
									</div>
								</div>
								<div class="list__item">
									<div class="card">
										<div class="member__post">Treasurer</div>
										<div class="member__name">Dr Finbar Fitzpatrick</div>
									</div>
								</div>
								<div class="list__item">
									<div class="card">
										<div class="member__post">Treasurer</div>
										<div class="member__name">Dr Claire Brennan</div>
									</div>
								</div>
								<div class="list__item">
									<div class="card">
										<div class="member__post">Committee Member </div>
										<div class="member__name">Dr Conor Brosnan</div>
									</div>
								</div>
								<div class="list__item">
									<div class="card">
										<div class="member__post">Committee Member </div>
										<div class="member__name">Dr Tríona Hussey</div>
									</div>
								</div>
							</div>
						</section>
						{/* <!-- /Members --> */}
					</div>
				</section>
				{/* <!-- /Membership --> */}
			</main>
  )
}

export default Membership
