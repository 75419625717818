import { GLOBAL_ACTIONS } from '@constants';

export const setLoading = (loading) => ({
  type: GLOBAL_ACTIONS.SET_LOADING,
  payload: { loading }
})

export const toggleLoginModal = (showLoginModal, showBuyTicketButton) => ({
  type: GLOBAL_ACTIONS.TOGGLE_LOGIN_MODAL,
  payload: {showLoginModal, showBuyTicketButton},
});

export const toggleRegisterModal = (showRegisterModal, showBuyTicketButton) => ({
  type: GLOBAL_ACTIONS.TOGGLE_REGISTER_MODAL,
  payload: {showRegisterModal, showBuyTicketButton}
})

export const setError = (error) => ({
  type: GLOBAL_ACTIONS.SET_ERROR,
  payload: {error},
});

export const clearError = () => ({
  type: GLOBAL_ACTIONS.CLEAR_ERROR,
  payload: {},
});

export const setSuccess = (successMessage) => ({
  type: GLOBAL_ACTIONS.SET_SUCCESS,
  payload: {successMessage},
});

export const clearSuccess = () => ({
  type: GLOBAL_ACTIONS.CLEAR_SUCCESS,
  payload: {},
});

export const sendContactUs = (contact_us) => ({
  type: GLOBAL_ACTIONS.SEND_CONTACT_US,
  payload: {contact_us},
});

export const sendContactUsSuccess = (successMessage) => ({
  type: GLOBAL_ACTIONS.SEND_CONTACT_US_SUCCESS,
  payload: {successMessage},
});

export const sendContactUsError = (error) => ({
  type: GLOBAL_ACTIONS.SEND_CONTACT_US_ERROR,
  payload: {error},
});

export const toggleSuccessModal = (modal) => ({
  type: GLOBAL_ACTIONS.TOGGLE_SUCCESS_POPUP,
  payload: {modal}
})

export const toggleReportModal = (reportUserId, toggle) => ({
  type: GLOBAL_ACTIONS.TOGGLE_REPORT_MODAL,
  payload: {reportUserId, toggle}
})
