import { filterBlackList } from '@helpers';
import { INITIAL_STATE, WEBINARS_ACTIONS } from '@constants';

const webinarsReducer = (state = INITIAL_STATE.WebinarsInitialState, action) => {
  const {type, payload} = action || {};
  const {
    meta,
    webinars,
    webinar,
    presentations,
    total_pages,
    links,
    replace = false,
  } = payload || {};
  switch (type) {
    case WEBINARS_ACTIONS.GET_WEBINARS: {
      const newMeta = meta || state.meta;
      return {...state, meta: newMeta};
    }

    case WEBINARS_ACTIONS.GET_WEBINARS_SUCCESS: {
      const {page} = state.meta || {};

      const cleanedWebinars = webinars?.map(webinar => ({ ...webinar, speaker: webinar?.speaker?.data?.map(speaker => speaker.attributes) }))
      
      const newWebinars = page === 1 ? cleanedWebinars : [...state.webinars, ...cleanedWebinars];

      if(replace) {
        return { 
          ...state,
          webinars: [ ...cleanedWebinars],
          meta: {
            ...state.meta,
            total_pages: total_pages
          }
        };
      } else {
        return { 
          ...state,
          webinars: newWebinars,
          meta: {
            ...state.meta,
            total_pages: total_pages
          },
        };
      }
    }

    case WEBINARS_ACTIONS.GET_PRESENTATIONS: {
      const newMeta = meta || state.presentationMeta;
      return {...state, presentationMeta: newMeta};
    }

    case WEBINARS_ACTIONS.GET_PRESENTATIONS_SUCCESS: {
      const {page} = state.presentationMeta || {};
      
      const newPresentations = page === 1 ? presentations : [...state.presentations, ...presentations];

      if(replace) {
        return { 
          ...state,
          presentations: [ ...presentations],
          presentationMeta: {
            ...state.presentationMeta,
            total_pages: total_pages
          }
        };
      } else {
        return { 
          ...state,
          presentations: newPresentations,
          presentationMeta: {
            ...state.presentationMeta,
            total_pages: total_pages
          },
        };
      }
    }

    case WEBINARS_ACTIONS.GET_WEBINAR_SUCCESS: {
      const cleanedWebinar = { ...webinar, speaker: webinar?.speaker?.data?.map(speaker => speaker.attributes) }

      return { ...state, webinar: cleanedWebinar }
    }

    case WEBINARS_ACTIONS.GET_LINKS_SUCCESS: {
      const clinicWebistes = links.filter(link => link.link_type == 'for_clinicians');
      const patinetWebistes = links.filter(link => link.link_type == 'for_patients');

      return { ...state, clinic_websites: clinicWebistes, patients_websites: patinetWebistes }
    }

    default:
      return state;
  }
};

export {webinarsReducer};