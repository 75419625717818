import { AUTH_ACTIONS } from '@constants';

export const isAuthenticated = () => ({
  type: AUTH_ACTIONS.IS_AUTHENTICATED,
  payload: {},
});

export const login = (email, password, redirect = true) => ({
  type: AUTH_ACTIONS.LOGIN,
  payload: {email, password, redirect},
});

export const loginSuccess = (user) => ({
  type: AUTH_ACTIONS.LOGIN_SUCCESS,
  payload: {user},
});

export const loginError = (error) => ({
  type: AUTH_ACTIONS.LOGIN_ERROR,
  payload: {error},
});

export const signUp = (formData) => {
  return {
    type: AUTH_ACTIONS.SIGN_UP,
    payload: {formData},
  };
};

export const signUpSuccess = (user) => {
  return {
    type: AUTH_ACTIONS.SIGN_UP_SUCCESS,
    payload: {user},
  };
};

export const signUpError = (error) => {
  return {
    type: AUTH_ACTIONS.SIGN_UP_ERROR,
    payload: {error},
  };
};

export const logout = () => ({
  type: AUTH_ACTIONS.LOGOUT,
  payload: {},
});

export const logoutSuccess = () => ({
  type: AUTH_ACTIONS.LOGOUT_SUCCESS,
  payload: {user: null},
});

export const logoutError = (error) => ({
  type: AUTH_ACTIONS.LOGOUT_ERROR,
  payload: {error},
});

export const stepSet = (step) => ({
  type: AUTH_ACTIONS.STEP_SET,
  payload: {step}
})

export const stepAdd = () => ({
  type: AUTH_ACTIONS.STEP_ADD,
  payload: {}
})

export const stepDecrement = () => ({
  type: AUTH_ACTIONS.STEP_DECREMENT,
  payload: {}
})

export const buyerConfirmRequest = url => ({
  type: AUTH_ACTIONS.BUYER_CONFIRM_REQUEST,
  payload: {url},
});

export const buyerConfirmSuccess = buyer => ({
  type: AUTH_ACTIONS.BUYER_CONFIRM_SUCCESS,
  payload: {buyer},
});

export const buyerConfirmError = error => ({
  type: AUTH_ACTIONS.BUYER_CONFIRM_ERROR,
  payload: {error},
});

export const forgotPassword = email => ({
  type: AUTH_ACTIONS.FORGOT_PASSWORD,
  payload: {email},
});

export const forgotPasswordSuccess = () => ({
  type: AUTH_ACTIONS.FORGOT_PASSWORD_SUCCESS,
});

export const forgotPasswordError = error => ({
  type: AUTH_ACTIONS.FORGOT_PASSWORD_ERROR,
  payload: {error},
});

export const restorePassword = (passwords, restoreToken) => ({
  type: AUTH_ACTIONS.RESTORE_PASSWORD,
  payload: { passwords, restoreToken }
});

export const restorePasswordSuccess = () => ({
  type: AUTH_ACTIONS.RESTORE_PASSWORD_SUCCESS,
  payload: {}
});

export const restorePasswordError = (error) => ({
  type: AUTH_ACTIONS.RESTORE_PASSWORD_ERROR,
  payload: { error }
})
