import { PAYMENTS_ACTIONS, INITIAL_STATE } from '../constants';

export const paymentsReducer = (state = INITIAL_STATE.PaymentsInitialState, action) => { 
  const {type, payload} = action || {};
  const {
    payment,
    donateModal,
    open,
    proccessing,
    transactions,
    user
  } = payload || {};

  switch (type) {
    case PAYMENTS_ACTIONS.TOGGLE_DONATE_MODAL: {
      if(user) {
        return {
          ...state,
          donateModalVisible: !state.donateModalVisible,
          donateModal: { user }
        }
      }

      return {
        ...state,
        donateModalVisible: !state.donateModalVisible,
      } 
    }
    case PAYMENTS_ACTIONS.TOGGLE_IS_PROCCESSING: {
      if(proccessing) {
        return {
          ...state,
          isProcessing: proccessing
        }
      }

      return {
        ...state,
        isProcessing: !state.isProcessing
      }
    }

    case PAYMENTS_ACTIONS.GET_TRANSACTIONS_SUCCESS: {
      return { ...state, transactions }
    }

    default:
      return state;
  }
}