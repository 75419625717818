import WEBINARS_ACTIONS from './actions/webinars'
import PROFILE_ACTIONS from './actions/profile';
import AUTH_ACTIONS from './actions/auth';
import GLOBAL_ACTIONS from './actions/global';
import MESSAGING_ACTIONS from './actions/messagingActions'
import PAYMENTS_ACTIONS from './actions/payments'
import NOTIFICATIONS_ACTIONS from './actions/notifications';
import * as INITIAL_STATE from './initialState';

export const LOCAL_STORAGE = {
  AUTH_TOKEN: 'pcdsi_token',
  USER: 'pcdsi_user',
};

export {
  WEBINARS_ACTIONS,
  PROFILE_ACTIONS,
  AUTH_ACTIONS,
  GLOBAL_ACTIONS,
  MESSAGING_ACTIONS,
  PAYMENTS_ACTIONS,
  INITIAL_STATE,
  NOTIFICATIONS_ACTIONS,
};