import { takeEvery, select, put, call } from 'redux-saga/effects';
import { WEBINARS_ACTIONS } from '@constants';
import { webinarsActions, globalActions } from '../actions';
import { processRequest } from '@services/Api';
import { push } from 'connected-react-router';
import { ROUTES } from '@router/routes';
import { getUrl } from '@helpers';
import { saveAs } from 'file-saver';


function* handleGetWebinars(action) {
  try {
    const { payload } = action;
    const { meta, replace } = payload;
    const { page, perPage, query, scope } = meta || {};
    const requestQuery = `/webinars?page=${page}&per_page=${perPage}&scope=${scope}&query=${query}`
    const { data } = yield call(processRequest, requestQuery, 'GET');
    if(data) {
      console.log(data)
      yield put(webinarsActions.getWebinarsSuccess(data?.webinars?.data?.map(webinar => webinar.attributes), data?.total_pages, replace))
    }
  } catch (e) {
    yield put(webinarsActions.getWebinarsError(e))
    console.log(e)
  }
}

function* handleGetPresentations(action) {
  try {
    const { payload } = action;
    const { meta, replace } = payload;
    const { page, perPage, query } = meta || {};
    const requestQuery = `/presentations?page=${page}&per_page=${perPage}&query=${query}`
    const { data } = yield call(processRequest, requestQuery, 'GET');

    if(data) {
      console.log(data)
      yield put(webinarsActions.getPresentationsSuccess(data?.presentations?.data?.map(presentation => presentation.attributes), data?.total_pages, replace))
    }
  } catch (e) {
    yield put(webinarsActions.getPresentationsError(e))
    console.log(e)
  }
}

function* handleGetWebinar(action) {
  try {
    const { payload } = action;
    const { id } = payload;
    const requestQuery = `/webinars/${id}`
    const { data } = yield call(processRequest, requestQuery, 'GET');

    if(data) {
      yield put(webinarsActions.getWebinarSuccess(data?.webinars?.data?.attributes))
    }

  } catch (e) {
    console.log(e)
    yield put(webinarsActions.getWebinarsError(e))
  }
}

function* handleGetLinks(action) {
  try {
    const requestQuery = `/links`
    const { data } = yield call(processRequest, requestQuery, 'GET');

    if(data) {
      yield put(webinarsActions.getLinksSuccess(data?.links?.data?.map(link => link.attributes)))
    }

  } catch (e) {
    console.log(e)
    yield put(webinarsActions.getLinksError(e))
  }
}


export function* watchWebinarsSagas() {
  yield takeEvery(WEBINARS_ACTIONS.GET_WEBINARS, handleGetWebinars);
  yield takeEvery(WEBINARS_ACTIONS.GET_PRESENTATIONS, handleGetPresentations);
  yield takeEvery(WEBINARS_ACTIONS.GET_WEBINAR, handleGetWebinar);
  yield takeEvery(WEBINARS_ACTIONS.GET_LINKS, handleGetLinks);

}