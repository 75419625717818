import React from 'react'
import ContentWrapper from '@components/Markup/ContentWrapper'
import Membership from '@components/Membership'

const MembershipPage = () => {
  return (
    <ContentWrapper>
      <Membership />
    </ContentWrapper>
  )
}

export default MembershipPage
